import { useAppSelector } from 'app/hooks';

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';

import {
    ABTEST_IDENTITY_TYPE,
    ABTEST_STATUS,
    ABTEST_VARIANT_STATUS,
} from 'api/experiments.service';
import { AbTestExperiments } from 'slice/experiments.slice';
import { useParams } from 'react-router-dom';

import { useCallback, useEffect, useState } from 'react';

import AbTestVariantForm from 'components/experiments/AbTestVariantForm';
import {
    AbTestTrafficPoolsMember,
    RangeTrafficPool,
} from 'api/types/experiments-abtests';

import { CSSProperties } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

interface Props {
    onClose: () => void;
    onSave: Function;
    onSaveVariant: (e: any) => void;
}

export interface AbTestFormValues
    extends Omit<
        AbTestExperiments,
        'id' | 'dateCreated' | 'dateModified' | 'trafficPools'
    > {
    trafficPools: Array<string>;
}

export interface AbTestFormValuesConvertedTrafficPools
    extends Omit<AbTestFormValues, 'trafficPools'> {
    trafficPools: AbTestTrafficPoolsMember[];
}

export default function AbTestForm({ onClose, onSave, onSaveVariant }: Props) {
    const { abTests, abTestsStatus, abTestVariants } = useAppSelector(
        (state) => state.experiments
    );

    const [createNewVariant, setCreateNewVariant] = useState(false);

    const { id } = useParams();
    const [abTest, setAbTest] = useState(
        abTests.find((q) => id !== 'new' && q.id === Number(id))
    );

    const [variants] = useState(() => abTest?.variants || []);

    /**
     * create an array of 10 objects in the form of
     * {max: 10, min: 1,  pool: 1}, {max: 20, min: 11,  pool: 2}, etc
     * unclear if "pool" is needed
     */
    const getTrafficPoolsOptions = () => {
        let poolsConfigurationValues: {
            min: number;
            max: number;
            pool: number;
        }[] = [];
        for (var i = 0; i < 10; i++) {
            poolsConfigurationValues.push({
                max: (i + 1) * 10,
                min: i * 10 + 1,
                pool: i + 1,
            });
        }
        return poolsConfigurationValues;
    };

    const trafficPoolsOptions = getTrafficPoolsOptions();

    const memoizedGetInitialActiveTrafficPools = useCallback(
        (abTest: AbTestExperiments) => {
            let activePools = abTest?.trafficPools
                // for this basic implementation, filter out simple numbers and only return objects
                // @todo: handle the advanced editing mode for traffic pools
                .reduce(
                    (prev: string[], cur: AbTestTrafficPoolsMember | []) => {
                        if (cur instanceof Number) {
                            return prev;
                        }
                        let index = trafficPoolsOptions.findIndex((option) => {
                            return (
                                option.max === (cur as RangeTrafficPool).max &&
                                option.min === (cur as RangeTrafficPool).min
                            );
                        });
                        if (index !== -1) {
                            prev.push(index.toString());
                        }
                        return prev;
                    },
                    [] as string[]
                );
            return activePools.length > 0 ? activePools : [];
        },
        [trafficPoolsOptions]
    );

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors, isDirty, isValid, isSubmitting },
    } = useForm<AbTestFormValues>({
        defaultValues: {
            name: abTest?.name || '',
            label: abTest?.label || '',
            status:
                abTest?.status === undefined
                    ? ABTEST_STATUS.INACTIVE
                    : abTest?.status,
            finalVariant: abTest?.finalVariant || '',
            forcedVariant: abTest?.forcedVariant || '',
            bucketByIdentityType:
                abTest?.bucketByIdentityType || ABTEST_IDENTITY_TYPE.ACCOUNT,
            bucketAutomatically: Boolean(abTest?.bucketAutomatically) || false,
            ios: abTest?.ios || '',
            android: abTest?.android || '',
            kindle: abTest?.kindle || '',
            web: abTest?.web || '',
            category: abTest?.category || '',
            subcategory: abTest?.subcategory || '',
            productOwner: abTest?.productOwner || '',
            technicalOwner: abTest?.technicalOwner || '',
            trafficPools: abTest
                ? memoizedGetInitialActiveTrafficPools(abTest)
                : [],
        },
    });

    // this is for landing on the page directly by url where the abTest is not yet loaded
    useEffect(() => {
        if (abTests && !abTest) {
            let abTest = abTests.find(
                (q) => id !== 'new' && q.id === Number(id)
            );
            if (abTest) {
                setAbTest(abTest);

                setValue('name', abTest.name);
                setValue('label', abTest.label);
                setValue('status', abTest.status);
                setValue('finalVariant', abTest.finalVariant);
                setValue('forcedVariant', abTest.forcedVariant);
                setValue('bucketByIdentityType', abTest.bucketByIdentityType);
                setValue('bucketAutomatically', abTest.bucketAutomatically);
                setValue('ios', abTest.ios);
                setValue('android', abTest.android);
                setValue('kindle', abTest.kindle);
                setValue('web', abTest.web);
                setValue('category', abTest.category);
                setValue('subcategory', abTest.subcategory);
                setValue('productOwner', abTest.productOwner);
                setValue('technicalOwner', abTest.technicalOwner);
                setValue(
                    'trafficPools',
                    memoizedGetInitialActiveTrafficPools(abTest)
                );
            }
        }
    }, [abTests, abTest, id, setValue, memoizedGetInitialActiveTrafficPools]);

    const getFullAbTestVariantDataByVariantId = (variantId: string) => {
        return abTestVariants.find(
            (q) =>
                q.id ===
                Number(variantId.substring(variantId.lastIndexOf('/') + 1))
        );
    };

    const onSaveAbTest: SubmitHandler<AbTestFormValues> = (
        values: AbTestFormValues
    ) => {
        let valuesConvertedTrafficPools =
            {} as AbTestFormValuesConvertedTrafficPools;
        valuesConvertedTrafficPools.label = values.label;
        valuesConvertedTrafficPools.name = values.name;
        // @TODO notes?? Does it exist in current dash. do we want it in this one
        // valuesConvertedTrafficPools.notes = values.notes;
        valuesConvertedTrafficPools.status = values.status;
        valuesConvertedTrafficPools.ios = values.ios;
        valuesConvertedTrafficPools.android = values.android;
        valuesConvertedTrafficPools.kindle = values.kindle;
        valuesConvertedTrafficPools.web = values.web;
        valuesConvertedTrafficPools.bucketAutomatically =
            values.bucketAutomatically;
        valuesConvertedTrafficPools.bucketByIdentityType =
            values.bucketByIdentityType;
        valuesConvertedTrafficPools.trafficPools = values.trafficPools.map(
            (p: string) => trafficPoolsOptions[Number(p)]
        );
        // debug. swap below for above to force an error
        // (valuesConvertedTrafficPools as unknown as any).trafficPools =
        //     values.trafficPools.map((p) => 'cats');
        valuesConvertedTrafficPools.variants = values.variants;
        valuesConvertedTrafficPools.finalVariant = values.finalVariant;
        valuesConvertedTrafficPools.forcedVariant = values.forcedVariant;
        valuesConvertedTrafficPools.technicalOwner = values.technicalOwner;
        valuesConvertedTrafficPools.productOwner = values.productOwner;
        valuesConvertedTrafficPools.category = values.category;
        valuesConvertedTrafficPools.subcategory = values.subcategory;
        valuesConvertedTrafficPools.statusLabel = values.statusLabel;

        onSave(valuesConvertedTrafficPools);
        onClose();
    };

    const onSaveNewVariant = (e: any) => {
        setCreateNewVariant(false);
        onSaveVariant(e);
    };

    const styles: { [key: string]: CSSProperties } = {
        variantAccordianHeaderContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        variantAccordianHeaderRightContainer: {
            textAlign: 'right',
            paddingRight: '1rem',
        },
        trafficPoolLabelColumn: {
            maxWidth: '150px',
        },
        variantsTitleContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: '1rem',
        },
        datesStyle: {
            fontSize: '0.7rem',
            fontWeight: '700',
        },
    };

    const onError = (errors: any, e: any) => console.log(errors, e);

    const watchTrafficPools = watch('trafficPools');

    return (
        <Modal
            size="lg"
            show={abTestsStatus !== 'loading'}
            onHide={onClose}
            backdrop="static"
        >
            {id !== 'new' && !abTest ? (
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>AB Test Not Found</Modal.Title>
                    </Modal.Header>
                </div>
            ) : (
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>{`${
                            !abTest ? 'New' : 'Edit'
                        } AB Test`}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div style={styles.datesStyle}>
                            <p>
                                Date Created:{' '}
                                {abTest &&
                                    new Date(abTest?.dateCreated).toString()}
                            </p>
                            <p>
                                Date Modified:{' '}
                                {abTest &&
                                    new Date(abTest?.dateModified).toString()}
                            </p>
                        </div>
                    </Modal.Body>

                    <form
                        noValidate
                        onSubmit={handleSubmit(onSaveAbTest, onError)}
                        className="ab-test-form"
                    >
                        <Modal.Body>
                            <Row>
                                <Col>
                                    {/* name */}
                                    <div className="mb-3">
                                        <div>
                                            <label htmlFor="name">Name</label>
                                        </div>
                                        <input
                                            type="text"
                                            {...register('name', {
                                                required: true,
                                            })}
                                        />
                                        {errors.name && (
                                            <span>Name is Required</span>
                                        )}
                                    </div>

                                    {/* label */}
                                    <div className="mb-3">
                                        <label htmlFor="label">Label</label>
                                        <input
                                            type="text"
                                            {...register('label', {
                                                required: true,
                                            })}
                                        />
                                        {errors.label && (
                                            <span>Label is Required</span>
                                        )}
                                    </div>

                                    {/* status */}
                                    <div className="mb-3">
                                        <label htmlFor="status">Status</label>
                                        <select {...register('status')}>
                                            {Object.entries(ABTEST_STATUS).map(
                                                ([name, value], index) => (
                                                    <option
                                                        key={index}
                                                        value={value}
                                                    >
                                                        {name.toLowerCase()}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {/* final variant */}
                                    <div className="mb-3">
                                        <label htmlFor="finalVariant">
                                            Final Variant
                                        </label>
                                        <select
                                            disabled={
                                                abTest?.status !==
                                                    ABTEST_STATUS.FINAL ||
                                                !variants.length
                                            }
                                            {...register('finalVariant')}
                                        >
                                            {variants?.map(
                                                (variant: string, index) => (
                                                    <option
                                                        key={index}
                                                        value={variant}
                                                    >
                                                        {
                                                            getFullAbTestVariantDataByVariantId(
                                                                variant
                                                            )?.label
                                                        }
                                                    </option>
                                                )
                                            )}
                                        </select>
                                        {!(
                                            abTest?.status ===
                                            ABTEST_STATUS.FINAL
                                        ) && (
                                            <div className="text-muted">
                                                AB Test needs to be in 'final'
                                                state.
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col>
                                    {/* forced variant */}
                                    <div className="mb-3">
                                        <label htmlFor="forcedVariant">
                                            Forced Variant
                                        </label>
                                        <select
                                            disabled={
                                                abTest?.status ===
                                                    ABTEST_STATUS.FINAL ||
                                                !variants.length
                                            }
                                            {...register('forcedVariant')}
                                        >
                                            <option key="default" value="">
                                                --
                                            </option>
                                            {variants?.map(
                                                (variant: string, index) => (
                                                    <option
                                                        key={index}
                                                        value={variant}
                                                    >
                                                        {
                                                            getFullAbTestVariantDataByVariantId(
                                                                variant
                                                            )?.label
                                                        }
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-4">
                                <Col>
                                    {/* Bucket By Identity Type */}
                                    <div className="mb-3">
                                        <label htmlFor="bucketByIdentityType">
                                            Bucket By Identity Type
                                        </label>
                                        <select
                                            disabled={
                                                abTest?.status ===
                                                ABTEST_STATUS.ACTIVE
                                            }
                                            {...register(
                                                'bucketByIdentityType'
                                            )}
                                        >
                                            {Object.values(
                                                ABTEST_IDENTITY_TYPE
                                            ).map((value, index) => (
                                                <option
                                                    key={index}
                                                    value={value}
                                                >
                                                    {value}
                                                </option>
                                            ))}
                                        </select>
                                        <div className="text-muted">
                                            Locked while Status is 'Active'
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    {/* Bucket Automatically */}
                                    <div className="mb-3">
                                        <label
                                            htmlFor="bucketAutomatically"
                                            className="inline"
                                        >
                                            BucketAutomatically
                                        </label>
                                        <input
                                            type="checkbox"
                                            {...register('bucketAutomatically')}
                                            readOnly={
                                                abTest?.status ===
                                                ABTEST_STATUS.ACTIVE
                                            }
                                        />
                                        <div className="text-muted">
                                            Locked while Status is 'Active'
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="mb-4">
                                <Col>
                                    <h5>Devices</h5>
                                    <div className="text-muted">
                                        Locked while Status is 'Active'
                                    </div>

                                    {/* iOS */}
                                    <div className="mb-3">
                                        <label htmlFor="ios">iOS</label>
                                        <input
                                            type="text"
                                            readOnly={
                                                abTest?.status ===
                                                ABTEST_STATUS.ACTIVE
                                            }
                                            {...register('ios')}
                                        />
                                    </div>

                                    {/* android */}
                                    <div className="mb-3">
                                        <label htmlFor="android">Android</label>
                                        <input
                                            type="text"
                                            readOnly={
                                                abTest?.status ===
                                                ABTEST_STATUS.ACTIVE
                                            }
                                            {...register('android')}
                                        />
                                    </div>

                                    {/* kindle */}
                                    <div className="mb-3">
                                        <label htmlFor="kindle">Kindle</label>
                                        <input
                                            type="text"
                                            readOnly={
                                                abTest?.status ===
                                                ABTEST_STATUS.ACTIVE
                                            }
                                            {...register('kindle')}
                                        />
                                    </div>

                                    {/* web */}
                                    <div className="mb-3">
                                        <label htmlFor="web">Web</label>
                                        <input
                                            type="text"
                                            readOnly={
                                                abTest?.status ===
                                                ABTEST_STATUS.ACTIVE
                                            }
                                            {...register('web')}
                                        />
                                    </div>
                                </Col>

                                <Col>
                                    <h5>Information</h5>
                                    <div className="text-muted">
                                        Locked while Status is 'Active'
                                    </div>

                                    {/* category */}
                                    <div className="mb-3">
                                        <label htmlFor="category">
                                            Category
                                        </label>
                                        <input
                                            type="text"
                                            readOnly={
                                                abTest?.status ===
                                                ABTEST_STATUS.ACTIVE
                                            }
                                            {...register('category')}
                                        />
                                    </div>

                                    {/* subcategory */}
                                    <div className="mb-3">
                                        <label htmlFor="subcategory">
                                            Subcategory
                                        </label>
                                        <input
                                            type="text"
                                            readOnly={
                                                abTest?.status ===
                                                ABTEST_STATUS.ACTIVE
                                            }
                                            {...register('subcategory')}
                                        />
                                    </div>

                                    {/* product owner */}
                                    <div className="mb-3">
                                        <label htmlFor="productOwner">
                                            Product Owner
                                        </label>
                                        <input
                                            type="text"
                                            readOnly={
                                                abTest?.status ===
                                                ABTEST_STATUS.ACTIVE
                                            }
                                            {...register('productOwner')}
                                        />
                                    </div>

                                    {/* technical owner */}
                                    <div className="mb-3">
                                        <label htmlFor="technicalOwner">
                                            Technical Owner
                                        </label>
                                        <input
                                            type="text"
                                            readOnly={
                                                abTest?.status ===
                                                ABTEST_STATUS.ACTIVE
                                            }
                                            {...register('technicalOwner')}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <h5>Pools Configuration</h5>
                                <Row>
                                    <Col xs={6} style={styles.datesStyle}>
                                        NOTE: Advanced configuration currently
                                        unavailable. This will also break any
                                        advanced configuration you currently
                                        have in place if you save the test in
                                        this interface
                                    </Col>
                                </Row>
                                <Col>
                                    {trafficPoolsOptions.map(
                                        (trafficPoolsOption, index) => (
                                            <Row key={index}>
                                                <Col
                                                    style={
                                                        styles.trafficPoolLabelColumn
                                                    }
                                                >
                                                    <label className="inline">
                                                        Traffic Pool {index + 1}
                                                    </label>
                                                    <input
                                                        type="checkbox"
                                                        value={index}
                                                        {...register(
                                                            'trafficPools'
                                                        )}
                                                    />
                                                </Col>
                                                <Col>
                                                    <div>
                                                        {watchTrafficPools.length >
                                                            0 &&
                                                            getValues(
                                                                'trafficPools'
                                                            )?.indexOf(
                                                                index.toString()
                                                            ) >= 0 && (
                                                                <div>
                                                                    {`${trafficPoolsOption.min} - ${trafficPoolsOption.max}`}
                                                                </div>
                                                            )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    )}
                                </Col>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="secondary" onClick={onClose}>
                                Close
                            </Button>
                            <Button
                                type="submit"
                                disabled={!isDirty || !isValid || isSubmitting}
                                variant="primary"
                            >
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </form>
                    <Modal.Body>
                        <Modal.Title>
                            <div style={styles.variantsTitleContainer}>
                                <div>Experiment Variants</div>
                                <Button
                                    variant="outline-primary"
                                    size="sm"
                                    onClick={() => setCreateNewVariant(true)}
                                    disabled={!abTest}
                                >
                                    Add New Variant
                                </Button>
                            </div>
                        </Modal.Title>
                        {id && (
                            <Accordion defaultActiveKey={['new']} alwaysOpen>
                                {createNewVariant && (
                                    <Accordion.Item eventKey={'new'}>
                                        <Accordion.Header>
                                            CREATE NEW VARIANT
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <AbTestVariantForm
                                                onSave={onSaveNewVariant}
                                            ></AbTestVariantForm>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                                {abTestVariants
                                    ?.filter(
                                        (q) =>
                                            abTest?.id ===
                                            Number(
                                                q.test.substring(
                                                    q.test.lastIndexOf('/') + 1
                                                )
                                            )
                                    )
                                    .sort((a: any, b: any) => {
                                        const aUnix = new Date(
                                            a.dateCreated
                                        ).getTime();
                                        const bUnix = new Date(
                                            b.dateCreated
                                        ).getTime();
                                        return aUnix - bUnix;
                                    })
                                    .map((v, i) => (
                                        <Accordion.Item
                                            eventKey={i.toString()}
                                            key={i}
                                        >
                                            <Accordion.Header>
                                                <div
                                                    style={
                                                        styles.variantAccordianHeaderContainer
                                                    }
                                                >
                                                    <div>{`${v.label}`}</div>
                                                    <div
                                                        style={
                                                            styles.variantAccordianHeaderRightContainer
                                                        }
                                                    >
                                                        <div>{`${
                                                            Object.entries(
                                                                ABTEST_VARIANT_STATUS
                                                            )[v.status][0]
                                                        }`}</div>
                                                        <div>{`Weight: ${v.weight}`}</div>
                                                    </div>
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AbTestVariantForm
                                                    id={v.id}
                                                    onSave={onSaveVariant}
                                                ></AbTestVariantForm>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}
                            </Accordion>
                        )}
                    </Modal.Body>
                </div>
            )}
        </Modal>
    );
}
