import numeral from 'numeral';

export const trimId = (id: string): string => {
    return id.split('-').pop() || '';
};

export const displayCurrency = (amount: string, currency: string): string => {
    switch (currency.toUpperCase()) {
        case 'INR':
            return '₹' + numeral(amount).format('0.00');
        default:
            return numeral(amount).format('$0,0.00');
    }
};

export const compareNumber = (a: number, b: number, asc: boolean) => {
    const modifier = asc ? -1 : 1;
    if (a > b) {
        return 1 * modifier;
    } else if (a < b) {
        return -1 * modifier;
    }
    return 0;
};

export const compareString = (a: string, b: string, asc: boolean) => {
    a = a.toLocaleLowerCase();
    b = b.toLocaleLowerCase();
    const modifier = asc ? -1 : 1;
    if (a > b) {
        return 1 * modifier;
    } else if (a < b) {
        return -1 * modifier;
    }
    return 0;
};
