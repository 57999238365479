import { API_HOST } from 'config';
import { encode } from 'querystring';

export const get = async (url: string): Promise<Response> => {
    return fetch(url);
};

export const put = async (
    url: string,
    body: object = {},
    headers: HeadersInit = []
): Promise<Response> => {
    return fetch(url, { method: 'PUT', body: JSON.stringify(body), headers });
};

export const post = async (
    url: string,
    body: object = {},
    headers: HeadersInit = []
): Promise<Response> => {
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers,
    });
};

export const legacyGet = async (
    className: string,
    methodName: string,
    params?: object
): Promise<Response> => {
    const query = encode({
        class: className,
        method: methodName,
        ...params,
    });

    const pathPrefix = API_HOST.match(/docker/) || !API_HOST ? '/api' : '';
    return fetch(`${API_HOST}${pathPrefix}/webapi/index.php?${query}`, {
        credentials: 'include',
    });
};

export const proxyGet = async (url: string): Promise<Response> => {
    const query = encode({ url });
    return fetch(`/api/proxy?${query}`);
};

export const fetchPost = async (url: string, data = {}): Promise<Response> => {
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
};
