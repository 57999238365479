import React from 'react';
import Login from 'components/Login';

export default function LoginPage() {
    return (
        <div className="container-sm d-flex justify-content-center">
            <Login />
        </div>
    );
}
