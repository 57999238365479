import React, { useEffect, useState } from 'react';
import PaymentsAccounts from 'components/payments/PaymentsAccounts';
import PaymentsHeader from 'components/payments/PaymentsHeader';
import PageHeader from 'components/PageHeader';
import SearchInput from 'components/SearchInput';
import {
    getAccountsFilterKey,
    getAsyncPaymentsAccounts,
} from 'slice/payments.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import PageScrollHandler from 'components/PageScrollHandler';

export default function PaymentsAccountsPage() {
    const { accounts, accountsCount, accountsStatus } = useAppSelector(
        (state) => state.payments
    );
    const dispatch = useAppDispatch();
    const [emailFilter, setEmailFilter] = useState('');
    const [emailQuery, setEmailQuery] = useState('');
    const [closeToBottom, setCloseToBottom] = useState(false);
    const [pages, setPages] = useState<{ [key: string]: number }>({ '': 1 });

    const displayedAccounts = accounts.filter(
        (q) => !emailFilter || q.email.indexOf(emailFilter) !== -1
    );

    const filterKey = getAccountsFilterKey({ email: emailQuery });
    const queriedCount = accountsCount[filterKey] || 0;

    const scrollEvent = (isCloseToBottom: boolean) => {
        setCloseToBottom(isCloseToBottom);
    };

    const onTriggerEmailQuery = (email: string) => {
        setEmailQuery(email);
        setPages({ ...pages, [getAccountsFilterKey({ email })]: 1 });
    };

    if (
        closeToBottom &&
        displayedAccounts.length < queriedCount &&
        accountsStatus !== 'loading'
    ) {
        let page = (pages[filterKey] || 1) + 1;
        setPages({ ...pages, [filterKey]: page });
        setCloseToBottom(false);
    }

    useEffect(() => {
        const page = pages[filterKey] || 1;
        dispatch(getAsyncPaymentsAccounts({ page, email: emailQuery }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, pages, emailQuery]);

    return (
        <div className="p-2">
            <PaymentsHeader currentPage="accounts" />
            <PageHeader>Payments Accounts</PageHeader>
            <PageScrollHandler scrollEvent={scrollEvent} pixelsToBottom={300} />
            <form>
                <div className="row mb-3">
                    <div className="col-auto">
                        <label htmlFor="emailInput" className="col-form-label">
                            Email
                        </label>
                    </div>
                    <div className="col-auto">
                        <SearchInput
                            className="form-control"
                            id="emailInput"
                            onChange={(e) => setEmailFilter(e.target.value)}
                            value={emailFilter}
                            onTriggerQuery={onTriggerEmailQuery}
                        />
                    </div>
                </div>
            </form>
            <PaymentsAccounts accounts={displayedAccounts} />
            {displayedAccounts.length >= queriedCount && (
                <div className="text-center my-2 fw-bold">
                    - no more accounts to fetch ({queriedCount}/{queriedCount})
                    -
                </div>
            )}
        </div>
    );
}
