import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { loginAsync } from 'slice/user.slice';

export default function Login() {
    const dispatch = useAppDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { error, status } = useAppSelector((s) => s.user);

    const disableForm = status === 'loading';

    const onSubmit = (e: React.MouseEvent) => {
        e.preventDefault();
        dispatch(loginAsync({ username, password }));
    };

    return (
        <form>
            <div className="mb-3">
                <label htmlFor="loginInput" className="form-label">
                    Username
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="loginInput"
                    onChange={(e) => setUsername(e.target.value)}
                    value={username}
                    disabled={disableForm}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="passwordInput" className="form-label">
                    Password
                </label>
                <input
                    type="password"
                    className="form-control"
                    id="passwordInput"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    disabled={disableForm}
                />
            </div>
            <button
                type="submit"
                className="btn btn-primary"
                onClick={onSubmit}
                disabled={disableForm}
            >
                Login
            </button>
            {!!error && <div className="text-danger py-2">{error}</div>}
        </form>
    );
}
