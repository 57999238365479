import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { PaymentsAccount, PaymentsTransaction } from 'slice/payments.slice';
import PaymentsTransactions from './PaymentsTransactions';

interface PaymentAccountsProps {
    account: PaymentsAccount;
    transactions: PaymentsTransaction[];
    transactionsStatus: string;
    onSave: (account: PaymentsAccount) => void;
}

export default function PaymentsAccountComponent({
    account,
    transactions,
    transactionsStatus,
    onSave,
}: PaymentAccountsProps) {
    const [email, setEmail] = useState('');

    useEffect(() => {
        setEmail(account.email);
    }, [account]);

    const clickOnSave = () => {
        onSave({ ...account, email });
    };

    return (
        <div>
            <div className="mb-4">
                <Form>
                    <Form.Group className="mb-3" controlId="formTestName">
                        <Row>
                            <Col>
                                <Form.Label>ID</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label>{account.id}</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Email</Form.Label>
                            </Col>
                            <Col>
                                <Form.Control
                                    type="text"
                                    value={email}
                                    onChange={(e: any) => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Created</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label>{account.created}</Form.Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Label>Updated</Form.Label>
                            </Col>
                            <Col>
                                <Form.Label>{account.updated}</Form.Label>
                            </Col>
                        </Row>
                        <Button className="mt-2" onClick={() => clickOnSave()}>
                            Save
                        </Button>
                    </Form.Group>
                </Form>
            </div>
            <div>
                <PaymentsTransactions
                    transactions={transactions
                        .slice()
                        .sort((a, b) =>
                            new Date(a.updated).getTime() >
                            new Date(b.updated).getTime()
                                ? -1
                                : 1
                        )}
                    hideEmail={true}
                />
            </div>
            {!transactions.length && transactionsStatus !== 'loading' && (
                <div>No Transactions</div>
            )}
            {!transactions.length && transactionsStatus === 'loading' && (
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            )}
        </div>
    );
}
