import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getAsyncPaymentsTransaction } from 'slice/payments.slice';
import GenericObject from 'components/generic/GenericObject';
import PaymentsHeader from 'components/payments/PaymentsHeader';

export default function PaymentsTransactionPage() {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { transactions } = useAppSelector((state) => state.payments);

    useEffect(() => {
        if (id) {
            dispatch(getAsyncPaymentsTransaction(id));
        }
    }, [dispatch, id]);

    const transaction = transactions.find((t) => t.id === id);
    return (
        <div className="p-2">
            <PaymentsHeader />
            <PageHeader>Transaction</PageHeader>
            {transaction && <GenericObject object={transaction} />}
        </div>
    );
}
