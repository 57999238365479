import { AbTestExperiments } from 'slice/experiments.slice';

import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import { useAppSelector } from 'app/hooks';
import SortableTableHeader from 'components/SortableTableHeader';
import { useState } from 'react';
import { compareNumber, compareString } from 'lib/util';
import Button from 'react-bootstrap/Button';

type SortBy = 'label' | 'type' | 'status' | 'auto' | 'variants' | 'dateCreated';

export default function AbTestList({
    displayedAbTests,
}: {
    displayedAbTests: AbTestExperiments[];
}) {
    const { abTestsStatus } = useAppSelector((state) => state.experiments);
    const [sortBy, setSortBy] = useState<SortBy>('dateCreated');
    const [sortDirectionIsAsc, setSortDirectionIsAsc] = useState(false);

    const changeSort = (nextSortBy: SortBy) => {
        nextSortBy === sortBy
            ? setSortDirectionIsAsc(!sortDirectionIsAsc)
            : setSortDirectionIsAsc(false);
        setSortBy(nextSortBy);
    };

    return (
        <div>
            <div className="experiments-abTestList">
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>
                                <SortableTableHeader
                                    onClick={() => changeSort('label')}
                                    selected={sortBy === 'label'}
                                    isAscending={sortDirectionIsAsc}
                                >
                                    Label
                                </SortableTableHeader>
                            </th>
                            <th>
                                <SortableTableHeader
                                    onClick={() => changeSort('type')}
                                    selected={sortBy === 'type'}
                                    isAscending={sortDirectionIsAsc}
                                >
                                    Type
                                </SortableTableHeader>
                            </th>
                            <th>
                                <SortableTableHeader
                                    onClick={() => changeSort('status')}
                                    selected={sortBy === 'status'}
                                    isAscending={sortDirectionIsAsc}
                                >
                                    Status
                                </SortableTableHeader>
                            </th>
                            <th>
                                <SortableTableHeader
                                    onClick={() => changeSort('auto')}
                                    selected={sortBy === 'auto'}
                                    isAscending={sortDirectionIsAsc}
                                >
                                    Auto
                                </SortableTableHeader>
                            </th>
                            <th>
                                <SortableTableHeader
                                    onClick={() => changeSort('variants')}
                                    selected={sortBy === 'variants'}
                                    isAscending={sortDirectionIsAsc}
                                >
                                    Variants
                                </SortableTableHeader>
                            </th>
                            <th>
                                <SortableTableHeader
                                    onClick={() => changeSort('dateCreated')}
                                    selected={sortBy === 'dateCreated'}
                                    isAscending={sortDirectionIsAsc}
                                >
                                    Date Created
                                </SortableTableHeader>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedAbTests
                            .sort((a, b) => {
                                switch (sortBy) {
                                    case 'label':
                                        return compareString(
                                            a.label,
                                            b.label,
                                            sortDirectionIsAsc
                                        );
                                    case 'type':
                                        return a.bucketByIdentityType &&
                                            b.bucketByIdentityType
                                            ? compareString(
                                                  a.bucketByIdentityType,
                                                  b.bucketByIdentityType,
                                                  sortDirectionIsAsc
                                              )
                                            : 0;
                                    case 'status':
                                        return a.status !== undefined &&
                                            b.status !== undefined
                                            ? compareNumber(
                                                  a.status,
                                                  b.status,
                                                  sortDirectionIsAsc
                                              )
                                            : 0;
                                    case 'auto':
                                        return a.bucketAutomatically !==
                                            undefined &&
                                            b.bucketAutomatically !== undefined
                                            ? compareNumber(
                                                  Number(a.bucketAutomatically),
                                                  Number(b.bucketAutomatically),
                                                  sortDirectionIsAsc
                                              )
                                            : 0;
                                    case 'variants':
                                        return compareNumber(
                                            a.variants.length,
                                            b.variants.length,
                                            sortDirectionIsAsc
                                        );
                                    default:
                                        return compareString(
                                            a.dateCreated,
                                            b.dateCreated,
                                            sortDirectionIsAsc
                                        );
                                }
                            })
                            .map((abTest) => (
                                <tr key={abTest.id}>
                                    <td>{abTest.label}</td>
                                    <td>{abTest.bucketByIdentityType}</td>
                                    <td>{abTest.statusLabel}</td>
                                    <td>
                                        {abTest.bucketAutomatically
                                            ? 'Yes'
                                            : 'No'}
                                    </td>
                                    <td>{abTest.variants?.length}</td>
                                    <td>
                                        {new Date(
                                            abTest.dateCreated
                                        ).toUTCString()}
                                    </td>
                                    <td>
                                        <Link
                                            to={`/experiments/abTests/${abTest.id}`}
                                        >
                                            <Button
                                                variant="outline-secondary"
                                                size="sm"
                                            >
                                                Edit
                                            </Button>
                                        </Link>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </div>
            {!Object.keys(displayedAbTests).length &&
                abTestsStatus !== 'loading' && (
                    <Alert variant="danger">No AB test data found</Alert>
                )}
        </div>
    );
}
