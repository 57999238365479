import { get } from 'api/api.service';
import { ADMIN_HOSTS } from 'lib/environments/environments.admin';
import { CONTENT_TOOL_HOSTS } from 'lib/environments/environments.content-tool';
import { WEB_HOSTS } from 'lib/environments/environments.web';

interface Environment {
    git: { branch: string; commit: string; date: string };
    timestamp: number;
}

type EnvironmentType = 'EpicWeb' | 'EpicWebContentTool' | 'web-admin';

export interface EnvironmentInfo {
    environment: Environment | null;
    host: string;
    type: EnvironmentType;
    meta?: Omit<EnvironmentMetaData, 'host'>;
}

export const getEnvironment = async (
    { host, build }: EnvironmentMetaData,
    type: EnvironmentType
): Promise<EnvironmentInfo> => {
    const response = await get(`${host}/build-info.json`).catch((e) => {});
    if (!response) {
        return { host, environment: null, type };
    }
    const environment: Environment = await response.json();

    return { host, environment, type, meta: { build } };
};

export const getEnvironments = async (): Promise<EnvironmentInfo[]> => {
    return Promise.all([
        ...WEB_HOSTS.map((host) => getEnvironment(host, 'EpicWeb')),
        ...ADMIN_HOSTS.map((host) => getEnvironment(host, 'web-admin')),
        ...CONTENT_TOOL_HOSTS.map((host) =>
            getEnvironment(host, 'EpicWebContentTool')
        ),
    ]);
};
