import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    EnvironmentInfo,
    getEnvironments,
    getEnvironment,
} from 'api/environments.service';

export interface EnvironmentsState {
    environments: EnvironmentInfo[];
    status: 'idle' | 'loading' | 'failure';
}

const initialState: EnvironmentsState = {
    environments: [],
    status: 'idle',
};

export const getAsyncEnvironment = createAsyncThunk(
    'environment/get',
    async (environment: string) => {
        const response = await getEnvironment(
            { host: environment },
            'web-admin'
        );
        return response;
    }
);

export const getAsyncEnvironments = createAsyncThunk(
    'environments/get',
    async () => {
        const response = await getEnvironments();
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const environmentSlice = createSlice({
    name: 'environments',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {},
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getAsyncEnvironments.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAsyncEnvironments.fulfilled, (state, action) => {
                state.status = 'idle';
                const newEnvData = action.payload;
                const newEnvHosts = newEnvData.map((q) => q.host);
                state.environments = state.environments
                    .filter(({ host }) => !newEnvHosts.includes(host))
                    .concat(newEnvData);
            })
            .addCase(getAsyncEnvironment.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getAsyncEnvironment.fulfilled, (state, action) => {
                state.status = 'idle';
                const newEnvData = action.payload;
                const newEnvHost = newEnvData.host;
                state.environments = state.environments
                    .filter(({ host }) => host !== newEnvHost)
                    .concat(newEnvData);
            });
    },
});

export default environmentSlice.reducer;
