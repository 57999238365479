import React, { useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { getAsyncPaymentsTransactionErrors } from 'slice/payments.slice';
import { trimId } from 'lib/util';
import moment from 'moment';
import PageScrollHandler from 'components/PageScrollHandler';
import { Link } from 'react-router-dom';

export default function PaymentsTransactionErrors() {
    const dispatch = useAppDispatch();
    const [page, setPage] = useState(0);
    const [closeToBottom, setCloseToBottom] = useState(false);
    const [displayedCount, setDisplayedCount] = useState(-1);
    const {
        transactionErrors,
        transactionErrorsStatus,
        transactionErrorsCount,
    } = useAppSelector((state) => state.payments);

    const scrollEvent = (isCloseToBottom: boolean) => {
        setCloseToBottom(isCloseToBottom);
    };

    // adds infinite scrolling, grab more transaction errors when the user
    // is close to the bottom of the page and they haven't fetched all of them yet
    if (
        (closeToBottom || page === 0) &&
        transactionErrorsStatus === 'idle' &&
        displayedCount < transactionErrors.length &&
        (transactionErrors.length < transactionErrorsCount || page === 0)
    ) {
        setDisplayedCount(transactionErrors.length);
        setPage(page + 1);
    }

    useEffect(() => {
        if (page) {
            dispatch(getAsyncPaymentsTransactionErrors({ page }));
        }
    }, [page, dispatch]);

    return (
        <div>
            <PageScrollHandler scrollEvent={scrollEvent} pixelsToBottom={300} />
            <table className="table">
                <thead>
                    <tr className="text-uppercase">
                        <th>id</th>
                        <th>code</th>
                        <th>message</th>
                        <th>transaction</th>
                        <th>created</th>
                    </tr>
                </thead>
                <tbody>
                    {transactionErrors
                        .slice()
                        .sort((a, b) => {
                            if (new Date(a.created) === new Date(b.created)) {
                                return 0;
                            }

                            return new Date(a.updated) > new Date(b.updated)
                                ? -1
                                : 1;
                        })
                        .map(
                            ({
                                id,
                                code,
                                message,
                                response,
                                transaction,
                                created,
                            }) => (
                                <tr key={id}>
                                    <td title={id}>{trimId(id)}</td>
                                    <td>{code}</td>
                                    <td>{message}</td>
                                    <td title={transaction}>
                                        <Link to={`/payments${transaction}`}>
                                            {trimId(transaction)}
                                        </Link>
                                    </td>
                                    <td>
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            {moment(created).fromNow()}
                                        </span>
                                    </td>
                                </tr>
                            )
                        )}
                </tbody>
            </table>
        </div>
    );
}
