import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { login, getUser, logout as logoutApi } from 'api/auth.service';

export interface UserState {
    user: object | null;
    status: 'idle' | 'loading' | 'failed';
    error: string | null;
    initialSet: boolean;
}

const initialState: UserState = {
    user: null,
    status: 'idle',
    error: null,
    initialSet: false,
};

export const loginAsync = createAsyncThunk(
    'user/login',
    async ({ username, password }: { username: string; password: string }) => {
        const response = await login(username, password);
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const getUserAsync = createAsyncThunk('user/getUser', async () => {
    const response = await getUser();
    // The value we return becomes the `fulfilled` action payload
    return response;
});

export const userSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        logout: (state: UserState) => {
            logoutApi();
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.user = null;
            state.status = 'idle';
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(loginAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loginAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                if (action.payload.user) {
                    state.user = action.payload.user;
                    state.error = null;
                } else {
                    state.user = null;
                    state.error = action.payload.error || 'unknown error';
                }
            })
            .addCase(getUserAsync.fulfilled, (state, action) => {
                state.initialSet = true;
                if (action.payload?.user) {
                    state.user = action.payload.user;
                } else {
                    state.user = null;
                }
            });
    },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
