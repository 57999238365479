import { createSlice } from '@reduxjs/toolkit';
import { ErrorState } from './types/error';

interface ErrorsState {
    errors: ErrorState[];
}

const initialState: ErrorsState = {
    errors: [],
};

export const errorsSlice = createSlice({
    name: 'errors',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        addError: (state: ErrorsState, action: { payload: ErrorState }) => {
            const error = action.payload;
            state.errors = state.errors
                .filter(({ key }) => key !== error.key)
                .concat(error);
        },
        removeError: (state: ErrorsState, action: { payload: ErrorState }) => {
            const error = action.payload;
            state.errors = state.errors.filter(({ key }) => key !== error.key);
        },
    },
});

export const { addError, removeError } = errorsSlice.actions;

export default errorsSlice.reducer;
