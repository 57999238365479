import React from 'react';
import { Link } from 'react-router-dom';

type Page = 'accounts' | 'transactionErrors' | 'transactions';

const pages: { title: string; page: Page; route: string }[] = [
    { title: 'Accounts', page: 'accounts', route: '/payments/accounts' },
    {
        title: 'Transaction Errors',
        page: 'transactionErrors',
        route: '/payments/transaction-errors',
    },
    {
        title: 'Transactions',
        page: 'transactions',
        route: '/payments/transactions',
    },
];

interface PaymentsHeaderProps {
    currentPage?: Page;
}

export default function PaymentsHeader({ currentPage }: PaymentsHeaderProps) {
    return (
        <div>
            {pages.map(({ title, page, route }) => (
                <Link
                    to={route}
                    key={page}
                    className={`${
                        page === currentPage && 'disabled-link'
                    } pe-4`}
                >
                    {title}
                </Link>
            ))}
        </div>
    );
}
