export class ApiError extends Error {
    code?: number;
    html?: string;

    constructor(message: string, code?: number, html?: string) {
        super(message);
        this.code = code;
        this.html = html;
    }
}
