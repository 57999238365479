import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getAccount,
    getAccounts,
    getTransaction,
    getTransactionErrors,
    getTransactions,
    putAccount,
} from 'api/payments.service';
import {
    PaymentsAccountsHydraMember as HydraMemberPaymentAccounts,
    PaymentsAccountsQuery,
} from 'api/types/payments-accounts-get';
import {
    PaymentsTransactionErrorsHydraMember as HydraMemberTransactionError,
    PaymentsTransactionErrorsQuery,
} from 'api/types/payments-transaction-errors-get';
import {
    PaymentTransactionsQuery,
    PaymentTransactionsHydraMember as HydraMemberTransaction,
    Account as TransactionAccount,
} from 'api/types/payments-transactions-get';
import { ApiError } from 'classes/ApiError';
import { ErrorState } from './types/error';

export interface PaymentsAccount
    extends Omit<HydraMemberPaymentAccounts, '@id' | '@type' | '@context'> {}

export interface PaymentsTransactionError
    extends Omit<HydraMemberTransactionError, '@id' | '@type' | '@context'> {}

export interface PaymentsTransaction
    extends Omit<
        HydraMemberTransaction,
        '@id' | '@type' | '@context' | 'account'
    > {
    account: Omit<TransactionAccount, '@id' | '@type' | '@context'>;
}

export interface PaymentsState {
    accounts: PaymentsAccount[];
    accountsStatus: FetchStatus;
    accountsCount: { [filters: string]: number };
    transactionErrors: PaymentsTransactionError[];
    transactionErrorsStatus: FetchStatus;
    transactionErrorsCount: number;
    transactions: PaymentsTransaction[];
    transactionsStatus: FetchStatus;
    transactionsCount: { [filters: string]: number };
    updateAccountStatus: FetchStatus;
    errors: ErrorState[];
}

const initialState: PaymentsState = {
    accounts: [],
    accountsStatus: 'idle',
    accountsCount: {},
    transactionErrors: [],
    transactionErrorsStatus: 'idle',
    transactionErrorsCount: 0,
    transactions: [],
    transactionsStatus: 'idle',
    transactionsCount: {},
    updateAccountStatus: 'idle',
    errors: [],
};

export const getAsyncPaymentsAccounts = createAsyncThunk(
    'payment/accounts/get',
    async (parameters?: PaymentsAccountsQuery) => {
        const page = parameters?.page || 1;
        const email = parameters?.email || '';
        return getAccounts(page, email);
    }
);

export const putAsyncPaymentsAccounts = createAsyncThunk(
    'payment/accounts/put',
    async ({ id, body }: { id: string; body: { email: string } }) => {
        return putAccount(id, body);
    }
);

export const getAsyncPaymentsAccount = createAsyncThunk(
    'payments/account/get',
    async (parameters: { id: string }) => {
        return getAccount(parameters.id);
    }
);

export const getAsyncPaymentsTransactionErrors = createAsyncThunk(
    'payment/transaction_errors/get',
    async (queryParams: PaymentsTransactionErrorsQuery = {}) => {
        return getTransactionErrors(queryParams);
    }
);

export const getAsyncPaymentsTransactions = createAsyncThunk(
    'payment/transactions/get',
    async (queryParams: PaymentTransactionsQuery = {}) => {
        return getTransactions(queryParams);
    }
);

export const getAsyncPaymentsTransaction = createAsyncThunk(
    'payment/transactions/get/{id}',
    async (id: string) => {
        return getTransaction(id);
    }
);

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        resetTransactions: (state: PaymentsState) => {
            state.transactions = [];
            state.transactionsCount = {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAsyncPaymentsAccounts.pending, (state) => {
                state.accountsStatus = 'loading';
            })
            .addCase(getAsyncPaymentsAccounts.fulfilled, (state, action) => {
                state.accountsStatus = 'idle';
                if (!action.payload) {
                    return;
                }

                state.accountsCount[
                    getAccountsFilterKey(action.meta.arg || {})
                ] = action.payload['hydra:totalItems'];
                const newAccounts = action.payload['hydra:member'].map(
                    ({ id, email, transactions, created, updated }) => ({
                        id,
                        email,
                        transactions,
                        created,
                        updated,
                    })
                );
                const newAccountIds = newAccounts.map((q) => q.id);
                state.accounts = state.accounts
                    .filter((q) => !newAccountIds.includes(q.id))
                    .concat(newAccounts);
            })
            .addCase(putAsyncPaymentsAccounts.pending, (state) => {
                state.updateAccountStatus = 'loading';
            })
            .addCase(putAsyncPaymentsAccounts.fulfilled, (state, action) => {
                state.updateAccountStatus = 'idle';
                if (!action.payload) {
                    return;
                }

                const { id, email, transactions, created, updated } =
                    action.payload;
                const newAccount = {
                    id,
                    email,
                    transactions,
                    created,
                    updated,
                };
                state.accounts = state.accounts
                    .filter((q) => id !== q.id)
                    .concat([newAccount]);
            })
            .addCase(getAsyncPaymentsAccount.pending, (state) => {
                state.accountsStatus = 'loading';
            })
            .addCase(getAsyncPaymentsAccount.fulfilled, (state, action) => {
                state.accountsStatus = 'idle';
                if (!action.payload) {
                    return;
                }
                const { id, email, transactions, created, updated } =
                    action.payload;
                const newAccount = {
                    id,
                    email,
                    transactions,
                    created,
                    updated,
                };
                state.accounts = state.accounts
                    .filter((q) => id !== q.id)
                    .concat([newAccount]);
            })
            .addCase(getAsyncPaymentsTransactions.pending, (state) => {
                state.transactionsStatus = 'loading';
            })
            .addCase(
                getAsyncPaymentsTransactions.fulfilled,
                (state, action) => {
                    if (action.payload instanceof ApiError) {
                        state.errors.push({
                            key: 'payment/transactions',
                            message: action.payload.message || 'unknown error',
                            html: action.payload.html,
                        });
                        state.transactionsStatus = 'failure';
                        return;
                    }

                    state.errors = state.errors.filter(
                        ({ key }) => key !== 'payment/transactions'
                    );
                    state.transactionsStatus = 'idle';
                    state.transactionsCount[
                        getTransactionsFilterKey(action.meta.arg || {})
                    ] = action.payload['hydra:totalItems'];
                    const newTransactions: PaymentsTransaction[] =
                        action.payload['hydra:member'].map(
                            ({
                                id,
                                amount,
                                currency,
                                productId,
                                gateway,
                                status,
                                method,
                                account,
                                transactionId,
                                statusName,
                                created,
                                updated,
                                transactionErrors,
                                transactionReference,
                                customerId,
                                gatewayName,
                                methodName,
                                transactionRefunds,
                            }) => ({
                                id,
                                amount,
                                currency,
                                productId,
                                gateway,
                                status,
                                method,
                                transactionId,
                                statusName,
                                created,
                                updated,
                                transactionErrors,
                                transactionReference,
                                customerId,
                                gatewayName,
                                methodName,
                                transactionRefunds,
                                account: {
                                    id: account.id,
                                    email: account.email,
                                    created: account.created,
                                    updated: account.updated,
                                },
                            })
                        );
                    const newTransactionIds = newTransactions.map((q) => q.id);
                    state.transactions = state.transactions
                        .filter(({ id }) => !newTransactionIds.includes(id))
                        .concat(newTransactions);
                }
            )
            .addCase(getAsyncPaymentsTransactionErrors.pending, (state) => {
                state.transactionErrorsStatus = 'loading';
            })
            .addCase(
                getAsyncPaymentsTransactionErrors.fulfilled,
                (state, action) => {
                    // if we get an error back set the error status and add to the list of
                    // errors for transactionErrors
                    if (action.payload instanceof ApiError) {
                        state.errors.push({
                            key: 'payment/transactionErrors',
                            message: action.payload.message || 'unknown error',
                            html: action.payload.html,
                        });
                        state.transactionsStatus = 'failure';
                        return;
                    }

                    // if we get a success response set status back to idle and
                    // clear any errors for transactionErrors
                    state.transactionErrorsStatus = 'idle';
                    state.errors = state.errors.filter(
                        ({ key }) => key !== 'payment/transactionErrors'
                    );

                    state.transactionErrorsCount =
                        action.payload['hydra:totalItems'];
                    const newTransactionErrors = action.payload[
                        'hydra:member'
                    ].map(
                        ({
                            id,
                            code,
                            message,
                            response,
                            transaction,
                            created,
                            updated,
                        }) => ({
                            id,
                            code,
                            message,
                            response,
                            transaction,
                            created,
                            updated,
                        })
                    );

                    const newTransactionErrorsIds = newTransactionErrors.map(
                        ({ id }) => id
                    );
                    state.transactionErrors = state.transactionErrors
                        .filter(
                            ({ id }) => !newTransactionErrorsIds.includes(id)
                        )
                        .concat(newTransactionErrors);
                }
            )
            .addCase(getAsyncPaymentsTransaction.pending, (state) => {
                state.transactionsStatus = 'loading';
            })
            .addCase(getAsyncPaymentsTransaction.fulfilled, (state, action) => {
                state.transactionsStatus = 'idle';
                if (!action.payload) {
                    return;
                }
                const transaction = action.payload;
                state.transactions = state.transactions
                    .filter((q) => transaction.id !== q.id)
                    .concat([transaction]);
            });
    },
});

export const { resetTransactions } = paymentsSlice.actions;

export const getTransactionsFilterKey = (
    query: PaymentTransactionsQuery
): string => {
    const values: string[] = [];
    if (query['account.email']) {
        values.push(query['account.email']);
    }
    if (query.productId) {
        values.push(query.productId);
    }

    return values.join('');
};

export const getAccountsFilterKey = (query: PaymentsAccountsQuery): string => {
    const values: string[] = [];
    if (query.email) {
        values.push(query.email);
    }

    return values.join('');
};

export default paymentsSlice.reducer;
