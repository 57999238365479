import React, { useEffect, useState } from 'react';
import Environments from 'components/Environments';

import { useAppSelector, useAppDispatch } from 'app/hooks';

import { getAsyncEnvironments } from 'slice/environments.slice';

export default function EnvironmentPage() {
    const dispatch = useAppDispatch();
    const { environments } = useAppSelector((state) => state.environments);

    useEffect(() => {
        dispatch(getAsyncEnvironments());
    }, [dispatch]);

    // eslint-disable-next-line
    const [seconds, setSeconds] = useState(0);
    const [hostFilter, setHostFilter] = useState('');
    const [activeHost, setActiveHost] = useState('');

    // use to auto update the times on the page
    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((seconds) => seconds + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const filteredEnvironments = environments.filter(
        ({ host }) => host.indexOf(hostFilter) !== -1
    );

    const webAdminEnvironments = filteredEnvironments.filter(
        ({ type }) => type === 'web-admin'
    );
    const webEnvironments = filteredEnvironments.filter(
        ({ type }) => type === 'EpicWeb'
    );
    const contentToolEnvironments = filteredEnvironments.filter(
        ({ type }) => type === 'EpicWebContentTool'
    );

    return (
        <div className="p-2">
            <form>
                <div className="row mb-3">
                    <div className="col-auto">
                        <label
                            htmlFor="hostFilterInput"
                            className="col-form-label"
                        >
                            Host
                        </label>
                    </div>
                    <div className="col-auto">
                        <input
                            className="form-control"
                            id="hostFilterInput"
                            onChange={(e) => setHostFilter(e.target.value)}
                            value={hostFilter}
                        />
                    </div>
                    <div className="col">
                        <div className="text-end">
                            <button
                                className="btn btn-small btn-secondary"
                                onClick={() => dispatch(getAsyncEnvironments())}
                            >
                                refresh
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <h2>EpicWeb</h2>
            <Environments
                environments={webEnvironments}
                activeHost={activeHost}
                setActiveHost={setActiveHost}
            />
            <h2 className="mt-4">Web Admin</h2>
            <Environments
                environments={webAdminEnvironments}
                activeHost={activeHost}
                setActiveHost={setActiveHost}
            />
            <h2 className="mt-4">Web Content Tool</h2>
            <Environments
                environments={contentToolEnvironments}
                activeHost={activeHost}
                setActiveHost={setActiveHost}
            />
        </div>
    );
}
