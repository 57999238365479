import React from 'react';

interface SortableTableHeaderProps {
    selected: boolean;
    onClick: () => void;
    isAscending: boolean;
    children: JSX.Element | string;
}

export default function SortableTableHeader({
    selected,
    onClick,
    isAscending,
    children,
}: SortableTableHeaderProps) {
    return (
        <span
            className={`sortable-table-header ${selected && 'selected'} ${
                isAscending && 'ascending'
            }`}
            onClick={() => onClick()}
        >
            {children}
        </span>
    );
}
