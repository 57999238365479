import React, { useState } from 'react';

export interface SearchInputProps {
    className?: string;
    id?: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    value: string;
    onTriggerQuery: (value: string) => void;
}

export default function SearchInput({
    className,
    id,
    onChange,
    value,
    onTriggerQuery,
}: SearchInputProps) {
    const [filterTimeout, setFilterTimeout] = useState(0);

    const delayTriggerQuery = (newValue: string) => {
        if (filterTimeout) {
            clearTimeout(filterTimeout);
        }

        // this will send a request to fetch a list accounts filtered by email once
        // the user stops typing in the email filter
        const timeout =
            setTimeout(() => {
                setFilterTimeout(0);
                console.log('trigger!');
                onTriggerQuery(newValue);
            }, 1000) || 0;
        setFilterTimeout(timeout as any);
    };

    const _preventEnterKeyDefault = (e: any) => {
        if (e.keyCode === 13) {
            // enter key
            e.preventDefault();
        }
    };

    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        delayTriggerQuery(e.target.value);
        onChange(e);
    };

    const _onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        _preventEnterKeyDefault(e);
    };

    return (
        <input
            type="text"
            className={className}
            id={id}
            onChange={_onChange}
            onKeyDown={_onKeyDown}
            value={value}
        />
    );
}
