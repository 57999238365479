import React, { useEffect, useState } from 'react';
import PaymentsHeader from 'components/payments/PaymentsHeader';
import PageHeader from 'components/PageHeader';
import PaymentsTransactions from 'components/payments/PaymentsTransactions';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import PageScrollHandler from 'components/PageScrollHandler';
import {
    getAsyncPaymentsTransactions,
    getTransactionsFilterKey,
    resetTransactions,
} from 'slice/payments.slice';
import SearchInput from 'components/SearchInput';

export default function PaymentsTransactionsPage() {
    const dispatch = useAppDispatch();
    const [closeToBottom, setCloseToBottom] = useState(false);
    const [emailFilter, setEmailFilter] = useState('');
    const [emailQuery, setEmailQuery] = useState('');
    const [productIdFilter, setProductIdFilter] = useState('');
    const [productIdQuery, setProductIdQuery] = useState('');
    const { transactions, transactionsStatus, transactionsCount } =
        useAppSelector((state) => state.payments);
    const [pages, setPages] = useState<{ [key: string]: number }>({ '': 1 });

    const filterKey = getTransactionsFilterKey({
        'account.email': emailQuery,
        productId: productIdQuery,
    });

    const queriedCount = transactionsCount[filterKey] || 0;
    const displayedTransactions = transactions.filter(
        (q) =>
            (emailFilter === '' ||
                q.account.email.indexOf(emailFilter) !== -1) &&
            (productIdFilter === '' ||
                q.productId.indexOf(productIdFilter) !== -1)
    );

    const scrollEvent = (isCloseToBottom: boolean) => {
        setCloseToBottom(isCloseToBottom);
    };

    const emailFilterTriggerQuery = (email: string) => {
        setEmailQuery(email);
        setPages({
            ...pages,
            [getTransactionsFilterKey({
                'account.email': email,
                productId: productIdQuery,
            })]: 1,
        });
    };

    const productIdFilterTriggerQuery = (productId: string) => {
        setProductIdQuery(productId);
        setPages({
            ...pages,
            [getTransactionsFilterKey({
                'account.email': emailQuery,
                productId,
            })]: 1,
        });
    };

    if (
        closeToBottom &&
        displayedTransactions.length < queriedCount &&
        transactionsStatus !== 'loading'
    ) {
        let page = (pages[filterKey] || 1) + 1;
        setPages({ ...pages, [filterKey]: page });
        setCloseToBottom(false);
    }

    const manualNextPage = (e: React.MouseEvent) => {
        e.preventDefault();
        let page = (pages[filterKey] || 1) + 1;
        setPages({ ...pages, [filterKey]: page });
    };

    const reset = () => {
        dispatch(resetTransactions());
        setCloseToBottom(false);
        setPages({ '': 1 });
        setEmailFilter('');
        setProductIdFilter('');
    };

    useEffect(() => {
        const page = pages[filterKey] || 1;
        dispatch(
            getAsyncPaymentsTransactions({
                page,
                'account.email': emailQuery,
                productId: productIdQuery,
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, pages, emailQuery, productIdQuery]);

    return (
        <div className="p-2">
            <PaymentsHeader currentPage="transactions" />
            <div className="d-flex justify-content-between">
                <PageHeader>Payments Transactions</PageHeader>
                <div>
                    <button
                        className="btn btn-small btn-secondary"
                        onClick={() => reset()}
                    >
                        refresh
                    </button>
                </div>
            </div>
            <form>
                <div className="row mb-3">
                    <div className="col-auto">
                        <label htmlFor="emailInput" className="col-form-label">
                            Email
                        </label>
                    </div>
                    <div className="col-auto">
                        <SearchInput
                            className="form-control"
                            id="emailInput"
                            onChange={(e) => setEmailFilter(e.target.value)}
                            value={emailFilter}
                            onTriggerQuery={emailFilterTriggerQuery}
                        />
                    </div>
                    <div className="col-auto">
                        <label
                            htmlFor="productIdInput"
                            className="col-form-label"
                        >
                            Product ID
                        </label>
                    </div>
                    <div className="col-auto">
                        <SearchInput
                            className="form-control"
                            id="productIdInput"
                            onChange={(e) => setProductIdFilter(e.target.value)}
                            value={productIdFilter}
                            onTriggerQuery={productIdFilterTriggerQuery}
                        />
                    </div>
                </div>
            </form>
            <div>
                <PageScrollHandler
                    scrollEvent={scrollEvent}
                    pixelsToBottom={300}
                />
                <PaymentsTransactions transactions={displayedTransactions} />
            </div>
            {displayedTransactions.length >= queriedCount && (
                <div className="text-center my-2 fw-bold">
                    - no more transactions to fetch ({queriedCount}/
                    {queriedCount}) -
                </div>
            )}
            {displayedTransactions.length < queriedCount &&
                transactionsStatus !== 'loading' && (
                    <div className="text-center">
                        <button
                            className="btn btn-link"
                            onClick={(e) => manualNextPage(e)}
                        >
                            Next Page
                        </button>
                    </div>
                )}
        </div>
    );
}
