import React, { useState } from 'react';
import { PaymentsTransaction } from 'slice/payments.slice';
import {
    compareNumber,
    compareString,
    displayCurrency,
    trimId,
} from 'lib/util';
import moment from 'moment';
import { Link } from 'react-router-dom';
import SortableTableHeader from 'components/SortableTableHeader';

export interface PaymentsTransactionsProps {
    transactions: PaymentsTransaction[];
    hideEmail?: boolean;
}

type SortBy =
    | 'id'
    | 'email'
    | 'amount'
    | 'productId'
    | 'errors'
    | 'status'
    | 'updated';

export default function PaymentsTransactions({
    transactions,
    hideEmail,
}: PaymentsTransactionsProps) {
    const [sortBy, setSortBy] = useState<SortBy>('updated');
    const [sortDirectionIsAsc, setSortDirectionIsAsc] = useState(false);

    const changeSort = (nextSortBy: SortBy) => {
        nextSortBy === sortBy
            ? setSortDirectionIsAsc(!sortDirectionIsAsc)
            : setSortDirectionIsAsc(false);
        setSortBy(nextSortBy);
    };

    return (
        <table className="table">
            <thead>
                <tr className="text-uppercase">
                    <th>
                        <SortableTableHeader
                            onClick={() => changeSort('id')}
                            selected={sortBy === 'id'}
                            isAscending={sortDirectionIsAsc}
                        >
                            id
                        </SortableTableHeader>
                    </th>
                    {!hideEmail && (
                        <th>
                            <SortableTableHeader
                                onClick={() => changeSort('email')}
                                selected={sortBy === 'email'}
                                isAscending={sortDirectionIsAsc}
                            >
                                email
                            </SortableTableHeader>
                        </th>
                    )}
                    <th>
                        <SortableTableHeader
                            onClick={() => changeSort('amount')}
                            selected={sortBy === 'amount'}
                            isAscending={sortDirectionIsAsc}
                        >
                            amount
                        </SortableTableHeader>
                    </th>
                    <th>
                        <SortableTableHeader
                            onClick={() => changeSort('productId')}
                            selected={sortBy === 'productId'}
                            isAscending={sortDirectionIsAsc}
                        >
                            product id
                        </SortableTableHeader>
                    </th>
                    <th>errors</th>
                    <th>
                        <SortableTableHeader
                            onClick={() => changeSort('status')}
                            selected={sortBy === 'status'}
                            isAscending={sortDirectionIsAsc}
                        >
                            status
                        </SortableTableHeader>
                    </th>
                    <th>
                        <SortableTableHeader
                            onClick={() => changeSort('updated')}
                            selected={sortBy === 'updated'}
                            isAscending={sortDirectionIsAsc}
                        >
                            updated
                        </SortableTableHeader>
                    </th>
                </tr>
            </thead>
            <tbody>
                {transactions
                    .sort((a, b) => {
                        switch (sortBy) {
                            case 'id':
                                return compareString(
                                    a.id,
                                    b.id,
                                    sortDirectionIsAsc
                                );
                            case 'email':
                                return compareString(
                                    a.account.email,
                                    b.account.email,
                                    sortDirectionIsAsc
                                );
                            case 'amount':
                                return compareNumber(
                                    parseInt(
                                        a.amount.replace(
                                            /((?!(\d|\.|,)).)*/,
                                            ''
                                        )
                                    ),
                                    parseInt(
                                        b.amount.replace(
                                            /((?!(\d|\.|,)).)*/,
                                            ''
                                        )
                                    ),
                                    sortDirectionIsAsc
                                );
                            case 'productId':
                                return compareString(
                                    a.productId,
                                    b.productId,
                                    sortDirectionIsAsc
                                );
                            case 'status':
                                return compareNumber(
                                    a.status,
                                    b.status,
                                    sortDirectionIsAsc
                                );
                            default:
                                return compareString(
                                    a.updated,
                                    b.updated,
                                    sortDirectionIsAsc
                                );
                        }
                    })
                    .map(
                        ({
                            id,
                            account,
                            amount,
                            productId,
                            updated,
                            currency,
                            statusName,
                            transactionErrors,
                        }) => (
                            <tr key={id}>
                                <td title={id} width="10%">
                                    <Link to={`/payments/transactions/${id}`}>
                                        {trimId(id)}
                                    </Link>
                                </td>
                                {!hideEmail && (
                                    <td>
                                        <div
                                            title={account.email}
                                            style={{
                                                overflow: 'hidden',
                                                maxWidth: 250,
                                            }}
                                        >
                                            <Link
                                                to={`/payments/accounts/${account.id}`}
                                            >
                                                {account.email}
                                            </Link>
                                        </div>
                                    </td>
                                )}
                                <td width="8%">
                                    {displayCurrency(amount, currency)}
                                </td>
                                <td width="20%">{productId}</td>
                                <td width="15%">
                                    {transactionErrors.map((error) => (
                                        <div
                                            key={error.id}
                                            title={error.message}
                                        >
                                            {error.code}
                                        </div>
                                    ))}
                                </td>
                                <td width="8%">{statusName}</td>
                                <td
                                    width="10%"
                                    style={{ whiteSpace: 'nowrap' }}
                                >
                                    {moment(updated).fromNow()}
                                </td>
                            </tr>
                        )
                    )}
            </tbody>
        </table>
    );
}
