import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getAbTest,
    getABTests,
    getJwtToken,
    getVariantsForAbTest,
    postAbTest,
    putAbTest,
    putAbtestVariant,
    postAbtestVariant,
} from 'api/experiments.service';
import {
    ABTestHydraMember,
    ABTestHydraError,
    ABTestVariantHydraMember,
    ExperimentsAbTestPutAndPostBody,
    ExperimentsAbTestVariantPutAndPostBody,
    ABTestsResponse,
} from 'api/types/experiments-abtests';
import {
    FeaturesHydraMember,
    ExperimentsFeaturesQuery,
    ExperimentsAbTestsQuery,
    ExperimentsAbTestQuery,
} from 'api/types/experiments-features';
import { getFeatures } from 'api/experiments.service';

import { ErrorState } from './types/error';
import { WritableDraft } from 'immer/dist/internal';

export interface FeaturesExperiments
    extends Omit<FeaturesHydraMember, '@id' | '@type' | '@context'> {}

export interface AbTestExperiments
    extends Omit<ABTestHydraMember, '@id' | '@type' | '@context'> {}

export interface AbTestVariantExperiments
    extends Omit<ABTestVariantHydraMember, '@id' | '@type' | '@context'> {}

export interface ExperimentsState {
    abTests: AbTestExperiments[];
    abTestsCount: { [filters: string]: number };
    abTestsStatus: FetchStatus;

    abTestVariants: AbTestVariantExperiments[];
    abTestVariantsStatus: FetchStatus;

    abTestStatus: FetchStatus;
    abTestVariantStatus: FetchStatus;

    features: FeaturesExperiments[];
    featuresCount: { [filters: string]: number };
    featuresStatus: FetchStatus;
    host: string;
    errors: ErrorState[];
}

const initialState: ExperimentsState = {
    abTests: [],
    abTestsCount: {},
    abTestsStatus: 'idle',
    abTestVariants: [],
    abTestVariantsStatus: 'idle',
    abTestStatus: 'idle',
    abTestVariantStatus: 'idle',
    features: [],
    featuresCount: {},
    featuresStatus: 'idle',
    host: '',
    errors: [],
};

type StateStatusValue =
    | 'abTestsStatus'
    | 'abTestStatus'
    | 'abTestVariantsStatus'
    | 'abTestVariantStatus'
    | 'featuresStatus';

export const getAsyncExperimentsAbTest = createAsyncThunk(
    'experiments/getAsyncExperimentsAbTest',
    async (parameters: ExperimentsAbTestQuery) => {
        return await getAbTest(parameters.id);
    }
);

export const putAsyncExperimentsAbTest = createAsyncThunk(
    'experiments/abTest/put',
    async ({
        id,
        body,
    }: {
        id: string;
        body: ExperimentsAbTestPutAndPostBody;
    }) => {
        return putAbTest(id, body);
    }
);
export const postAsyncExperimentsAbTest = createAsyncThunk(
    'experiments/abTest/post',
    async (body: ExperimentsAbTestPutAndPostBody) => {
        return postAbTest(body);
    }
);

export const getAsyncExperimentsAbTests = createAsyncThunk(
    'experiments/getAsyncExperimentsAbTests',
    async (parameters?: ExperimentsAbTestsQuery) => {
        return await getABTests(parameters?.page, parameters?.label);
    }
);
export const getAsyncExperimentsFeatures = createAsyncThunk(
    'experiments/getAsyncExperimentsFeatures',
    async (parameters?: ExperimentsFeaturesQuery) => {
        const response = await getFeatures(parameters?.page, parameters?.label);
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

// Ab Test Variants
export const getAsyncVariantsForAbTest = createAsyncThunk(
    'experiments/getAsyncVariantsForAbTest',
    async (testId: number) => {
        return getVariantsForAbTest(testId);
    }
);
export const putAsyncExperimentsAbTestVariant = createAsyncThunk(
    'experiments/putAsyncExperimentsAbTestVariant',
    async ({
        variantId,
        body,
    }: {
        variantId: string;
        body: ExperimentsAbTestVariantPutAndPostBody;
    }) => {
        return putAbtestVariant(variantId, body);
    }
);
export const postAsyncExperimentsAbTestVariant = createAsyncThunk(
    'experiments/postAsyncExperimentsAbTestVariant',
    async (body: ExperimentsAbTestVariantPutAndPostBody) => {
        return postAbtestVariant(body);
    }
);

export const getAsyncHost = createAsyncThunk(
    'experiments/getAsyncHost',
    async () => {
        const response = await getJwtToken();
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const experimentsSlice = createSlice({
    name: 'experiments',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAsyncHost.fulfilled, (state, action) => {
                state.host = action.payload.host;
            })
            // get one test
            .addCase(getAsyncExperimentsAbTest.pending, (state) => {
                state.abTestStatus = 'loading';
            })
            .addCase(getAsyncExperimentsAbTest.fulfilled, (state, action) => {
                const keyConstant = 'experiments/abTest/get';
                if (
                    setExperimentsResultsErrors(
                        state,
                        'abTestStatus',
                        action.payload,
                        keyConstant
                    )
                ) {
                    return;
                }
                state.errors = state.errors.filter(
                    ({ key }) => key !== keyConstant
                );

                state.abTestStatus = 'idle';

                const newAbTests = mapAbTestHydraToAbTest([action.payload]);
                state.abTests = getNewAbTestsState(newAbTests, state.abTests);
            })
            // get multiple tests
            .addCase(getAsyncExperimentsAbTests.pending, (state) => {
                state.abTestsStatus = 'loading';
            })
            .addCase(getAsyncExperimentsAbTests.fulfilled, (state, action) => {
                const keyConstant = 'experiments/abTests/get';
                if (
                    setExperimentsResultsErrors(
                        state,
                        'abTestsStatus',
                        action.payload,
                        keyConstant
                    )
                ) {
                    return;
                }
                state.errors = state.errors.filter(
                    ({ key }) => key !== keyConstant
                );

                state.abTestsStatus = 'idle';
                state.abTestsCount[getAbTestsFilterKey(action.meta.arg || {})] =
                    action.payload['hydra:totalItems'];
                const newAbTests = mapAbTestHydraToAbTest(
                    action.payload['hydra:member']
                );
                state.abTests = getNewAbTestsState(newAbTests, state.abTests);
            })
            .addCase(putAsyncExperimentsAbTest.pending, (state) => {
                state.abTestsStatus = 'loading';
            })
            .addCase(putAsyncExperimentsAbTest.fulfilled, (state, action) => {
                state.abTestsStatus = 'idle';
                const keyConstant = 'experiments/abTests/put';
                if (
                    setExperimentsResultsErrors(
                        state,
                        'abTestsStatus',
                        action.payload,
                        keyConstant
                    )
                ) {
                    return;
                }
                state.errors = state.errors.filter(
                    ({ key }) => key !== keyConstant
                );

                const newAbTests = mapAbTestHydraToAbTest([
                    action.payload as ABTestHydraMember,
                ]);
                state.abTests = getNewAbTestsState(newAbTests, state.abTests);
            })
            .addCase(postAsyncExperimentsAbTest.pending, (state) => {
                state.abTestsStatus = 'loading';
            })
            .addCase(postAsyncExperimentsAbTest.fulfilled, (state, action) => {
                state.abTestsStatus = 'idle';
                const keyConstant = 'experiments/abTests/post';
                if (
                    setExperimentsResultsErrors(
                        state,
                        'abTestsStatus',
                        action.payload,
                        keyConstant
                    )
                ) {
                    return;
                }
                state.errors = state.errors.filter(
                    ({ key }) => key !== keyConstant
                );

                const newAbTests = mapAbTestHydraToAbTest([
                    action.payload as ABTestHydraMember,
                ]);
                state.abTests = getNewAbTestsState(newAbTests, state.abTests);
            })
            .addCase(getAsyncVariantsForAbTest.pending, (state) => {
                state.abTestVariantsStatus = 'loading';
            })
            .addCase(getAsyncVariantsForAbTest.fulfilled, (state, action) => {
                // TODO: errors
                state.abTestVariantsStatus = 'idle';
                const newAbTestVariants = mapAbTestVariantHydraToAbTestVariant(
                    action.payload['hydra:member']
                );
                state.abTestVariants = getNewAbTestVariantsState(
                    newAbTestVariants,
                    state.abTestVariants
                );
            })
            .addCase(putAsyncExperimentsAbTestVariant.pending, (state) => {
                state.abTestVariantStatus = 'loading';
            })
            .addCase(
                putAsyncExperimentsAbTestVariant.fulfilled,
                (state, action) => {
                    const keyConstant = 'experiments/abTestVariants/put';
                    if (
                        setExperimentsResultsErrors(
                            state,
                            'abTestVariantStatus',
                            action.payload,
                            keyConstant
                        )
                    ) {
                        return;
                    }
                    state.errors = state.errors.filter(
                        ({ key }) => key !== keyConstant
                    );

                    state.abTestVariantStatus = 'idle';

                    const newAbTestVariants =
                        mapAbTestVariantHydraToAbTestVariant([action.payload]);
                    state.abTestVariants = getNewAbTestVariantsState(
                        newAbTestVariants,
                        state.abTestVariants
                    );

                    // refresh the abTest that this variant is for
                    let test = action.payload.test;
                    getAsyncExperimentsAbTest({
                        id: test.substring(test.lastIndexOf('/') + 1),
                    });
                }
            )
            .addCase(postAsyncExperimentsAbTestVariant.pending, (state) => {
                state.abTestVariantStatus = 'loading';
            })
            .addCase(
                postAsyncExperimentsAbTestVariant.fulfilled,
                (state, action) => {
                    const keyConstant = 'experiments/abTestVariants/post';
                    if (
                        setExperimentsResultsErrors(
                            state,
                            'abTestVariantStatus',
                            action.payload,
                            keyConstant
                        )
                    ) {
                        return;
                    }
                    state.errors = state.errors.filter(
                        ({ key }) => key !== keyConstant
                    );

                    state.abTestVariantStatus = 'idle';

                    const newAbTestVariants =
                        mapAbTestVariantHydraToAbTestVariant([action.payload]);
                    state.abTestVariants = getNewAbTestVariantsState(
                        newAbTestVariants,
                        state.abTestVariants
                    );

                    // refresh the abTest that this variant is for
                    let test = action.payload.test;
                    getAsyncExperimentsAbTest({
                        id: test.substring(test.lastIndexOf('/') + 1),
                    });
                }
            )
            .addCase(getAsyncExperimentsFeatures.pending, (state) => {
                state.featuresStatus = 'loading';
            })
            .addCase(getAsyncExperimentsFeatures.fulfilled, (state, action) => {
                // TODO: errors
                state.featuresStatus = 'idle';
                state.featuresCount[
                    getFeaturesFilterKey(action.meta.arg || {})
                ] = action.payload['hydra:totalItems'];
                const newFeatures: FeaturesExperiments[] = action.payload[
                    'hydra:member'
                ].map(
                    ({
                        id,
                        dateCreated,
                        dateModified,
                        category,
                        subcategory,
                        enabled,
                        label,
                        rules,
                        setting,
                        status,
                    }) => ({
                        id,
                        dateCreated,
                        dateModified,
                        category,
                        subcategory,
                        enabled,
                        label,
                        rules,
                        setting,
                        status,
                    })
                );
                const newFeatureIds = newFeatures.map((q) => q.id);
                state.features = state.features
                    .filter(({ id }) => !newFeatureIds.includes(id))
                    .concat(newFeatures);
            });
    },
});

export const getFeaturesFilterKey = (
    query: ExperimentsFeaturesQuery
): string => {
    const values: string[] = [];
    if (query.label) {
        values.push(query.label);
    }

    return values.join('');
};

export const getAbTestsFilterKey = (query: ExperimentsAbTestsQuery): string => {
    const values: string[] = [];
    if (query.label) {
        values.push(query.label);
    }

    return values.join('');
};

const mapAbTestHydraToAbTest = (
    payload: ABTestHydraMember[]
): AbTestExperiments[] => {
    return payload.map(
        ({
            id,
            label,
            name,
            notes,
            status,
            ios,
            android,
            kindle,
            web,
            bucketAutomatically,
            bucketByIdentityType,
            trafficPools,
            dateCreated,
            dateModified,
            variants,
            finalVariant,
            forcedVariant,
            technicalOwner,
            productOwner,
            category,
            subcategory,
            statusLabel,
        }) => ({
            id,
            label,
            name,
            notes,
            status,
            ios,
            android,
            kindle,
            web,
            bucketAutomatically,
            bucketByIdentityType,
            trafficPools,
            dateCreated,
            dateModified,
            variants,
            finalVariant,
            forcedVariant,
            technicalOwner,
            productOwner,
            category,
            subcategory,
            statusLabel,
        })
    );
};

const getNewAbTestsState = (
    newAbTests: AbTestExperiments[],
    currentAbTests: AbTestExperiments[]
) => {
    const newAbTestIds = newAbTests.map((q) => q.id);
    return currentAbTests
        .filter(({ id }) => !newAbTestIds.includes(id))
        .concat(newAbTests);
};

const mapAbTestVariantHydraToAbTestVariant = (
    payload: ABTestVariantHydraMember[]
): AbTestVariantExperiments[] => {
    return payload.map(
        ({
            id,
            label,
            description,
            status,
            test,
            weight,
            dateCreated,
            dateModified,
            featureRules,
            active,
            final,
            forced,
        }) => ({
            id,
            label,
            description,
            status,
            test,
            weight,
            dateCreated,
            dateModified,
            featureRules,
            active,
            final,
            forced,
        })
    );
};

const getNewAbTestVariantsState = (
    newAbTestVariants: AbTestVariantExperiments[],
    currentAbTestVariants: AbTestVariantExperiments[]
) => {
    const newAbTestIds = newAbTestVariants.map((q) => q.id);
    return currentAbTestVariants
        .filter(({ id }) => !newAbTestIds.includes(id))
        .concat(newAbTestVariants);
};

const setExperimentsResultsErrors = (
    state: WritableDraft<ExperimentsState>,
    erroredStateProp: StateStatusValue,
    actionPayload:
        | ABTestHydraMember
        | ABTestHydraError
        | ABTestVariantHydraMember
        | ABTestsResponse
        | null,
    key: string
): boolean => {
    // this list will probably grow as we see more types of errors
    // or we can get with Stephen and see if we can get a list
    if (
        !actionPayload ||
        actionPayload['@type'] === 'hydra:Error' ||
        actionPayload['@type'] === 'ConstraintViolationList'
    ) {
        let newErrorsArray = [...state.errors];
        newErrorsArray.push({
            key: key,
            message:
                (actionPayload as ABTestHydraError)['hydra:title'] ||
                'unknown error',
            html:
                (actionPayload as ABTestHydraError)['hydra:description'] || '',
        });
        state.errors = newErrorsArray;
        state[erroredStateProp] = 'failure';
        return true;
    }
    return false;
};

export default experimentsSlice.reducer;
