import React, { useState } from 'react';
import moment from 'moment';
import { EnvironmentInfo } from 'api/environments.service';
import SortableTableHeader from './SortableTableHeader';
import { compareNumber, compareString } from 'lib/util';

interface EnvironmentsProps {
    environments: EnvironmentInfo[];
    activeHost: string;
    setActiveHost: (host: string) => void;
}

type SortBy = 'host' | 'buildDate';

export default function Environment({
    environments,
    activeHost,
    setActiveHost,
}: EnvironmentsProps) {
    const [sortBy, setSortBy] = useState<SortBy>('host');
    const [sortDirectionIsAsc, setSortDirectionIsAsc] = useState(false);

    const changeSort = (nextSortBy: SortBy) => {
        nextSortBy === sortBy
            ? setSortDirectionIsAsc(!sortDirectionIsAsc)
            : setSortDirectionIsAsc(false);
        setSortBy(nextSortBy);
    };

    const getBranch = (branch: string | undefined) => {
        if (!branch || ['at', 'detached'].includes(branch)) {
            return 'n/a';
        }

        return branch;
    };
    return (
        <>
            <table className="table">
                <thead>
                    <tr className="text-uppercase">
                        <th>
                            <SortableTableHeader
                                onClick={() => changeSort('host')}
                                selected={sortBy === 'host'}
                                isAscending={sortDirectionIsAsc}
                            >
                                host
                            </SortableTableHeader>
                        </th>
                        <th>branch</th>
                        <th>commit hash</th>
                        <th>commit date</th>
                        <th>
                            <SortableTableHeader
                                onClick={() => changeSort('buildDate')}
                                selected={sortBy === 'buildDate'}
                                isAscending={sortDirectionIsAsc}
                            >
                                build date
                            </SortableTableHeader>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {environments
                        .sort((a, b) => {
                            switch (sortBy) {
                                case 'buildDate':
                                    return compareNumber(
                                        a.environment?.timestamp || 0,
                                        b.environment?.timestamp || 0,
                                        sortDirectionIsAsc
                                    );
                                default:
                                    return compareString(
                                        a.host,
                                        b.host,
                                        sortDirectionIsAsc
                                    );
                            }
                        })
                        .map(({ host, environment, type, meta }) => (
                            <>
                                <tr key={host}>
                                    <td width="35%">
                                        <a
                                            href={host}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {host.replace(
                                                /http(s):\/\/+/gi,
                                                ''
                                            )}
                                        </a>
                                        <button
                                            className="btn btn-light btn-sm ms-2"
                                            onClick={() =>
                                                setActiveHost(
                                                    activeHost === host
                                                        ? ''
                                                        : host
                                                )
                                            }
                                        >
                                            ▼
                                        </button>
                                    </td>
                                    <td width="25%">
                                        {getBranch(environment?.git.branch)}
                                    </td>
                                    <td width="10%">
                                        {environment?.git.commit && (
                                            <a
                                                href={`https://github.com/Epic/${type}/commit/${environment.git.commit}`}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {environment.git.commit.substring(
                                                    0,
                                                    7
                                                )}
                                            </a>
                                        )}
                                    </td>
                                    <td width="15%">
                                        {environment?.git.date
                                            ? moment(
                                                  new Date(
                                                      environment.git.date
                                                  ),
                                                  'DDHH'
                                              ).fromNow()
                                            : ''}
                                    </td>
                                    <td width="15%">
                                        {environment?.timestamp
                                            ? moment(
                                                  environment.timestamp * 1000
                                              ).fromNow()
                                            : ''}
                                    </td>
                                </tr>
                                {activeHost === host && (
                                    <tr>
                                        <td colSpan={3} className="ps-3">
                                            {!!meta?.build?.urls &&
                                                meta.build.urls.map((url) => (
                                                    <div key={url}>
                                                        <a href={url}>{url}</a>
                                                    </div>
                                                ))}
                                        </td>
                                        <td colSpan={2}>
                                            {!!meta?.build?.type && (
                                                <span className="ms-3">
                                                    ({meta.build.type})
                                                </span>
                                            )}
                                            {!!meta?.build?.description && (
                                                <span className="ms-2 fst-italic">
                                                    {meta.build.description}
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                )}
                            </>
                        ))}
                </tbody>
            </table>
        </>
    );
}
