import React from 'react';

interface PageHeaderProps {
    children: JSX.Element | string;
}

export default function PageHeader({ children }: PageHeaderProps) {
    return (
        <>
            <h3 className="mt-2">{children}</h3>
            <hr />
        </>
    );
}
