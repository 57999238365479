import { encode } from 'querystring';
import { legacyGet } from './api.service';
import {
    ABTestHydraMember,
    ABTestHydraError,
    ABTestsResponse,
    ABTestVariantHydraMember,
    ABTestVariantResponse,
    ExperimentsAbTestPutAndPostBody,
    ExperimentsAbTestVariantPutAndPostBody,
} from './types/experiments-abtests';
import { FeaturesResponse } from './types/experiments-features';
import { put, post } from 'api/api.service';
import { EXPERIMENTS_API_HOST, EXPERIMENTS_TOKEN } from 'config';

const MAX_RETRY_COUNT = 1;

export const ABTEST_STATUS = {
    ACTIVE: 0,
    INACTIVE: 1,
    FINAL: 2,
};

export const ABTEST_VARIANT_STATUS = {
    ACTIVE: 0,
    INACTIVE: 1,
    FINAL: 2,
    FORCED: 3,
};

export enum ABTEST_IDENTITY_TYPE {
    PERSON = 'person',
    ACCOUNT = 'account',
    DEVICE = 'device',
    UUID = 'uuid',
    USER = 'user',
}

let _authTokenPromise: Promise<{ token: string; host: string }> | null = null;

export const getJwtToken = (): Promise<{ host: string; token: string }> => {
    if (!_authTokenPromise) {
        _authTokenPromise = new Promise(async (resolve) => {
            if (EXPERIMENTS_API_HOST && EXPERIMENTS_TOKEN) {
                resolve({
                    host: EXPERIMENTS_API_HOST,
                    token: EXPERIMENTS_TOKEN,
                });
                return;
            }
            const response = await legacyGet(
                'WebAdminNew',
                'getExperimentsToken'
            );
            const data = await response?.json();
            const host = data?.result?.host.replace('http:', 'https:') || '';
            const token = data?.result?.token || '';

            resolve({ host, token });
        });
    }

    return _authTokenPromise;
};

const experimentsFetch = async (
    path: string,
    params?: string | Record<string, string>
) => {
    let { token, host } = await getJwtToken();
    let url = new URL(path, host).href;

    if (params) {
        const queryParams = new URLSearchParams(params);

        url += `?${queryParams.toString()}`;
    }

    return _fetch(url, token);
};

const experimentsPut = async (path: string, body: any): Promise<Response> => {
    let { token, host } = await getJwtToken();
    let url = new URL(path, host).href;

    const response = await put(url, body, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    }).catch((e) => {
        throw new Error(`request not successful`);
    });
    if (!response) {
        throw new Error(`request not successful`);
    }
    return response;
};

const experimentsPost = async (path: string, body: any): Promise<Response> => {
    let { token, host } = await getJwtToken();
    let url = new URL(path, host).href;

    const response = await post(url, body, {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    }).catch((e: any) => {
        throw new Error(`request not successful`);
    });
    if (!response) {
        throw new Error(`request not successful`);
    }
    return response;
};

const _fetch = async (
    url: string,
    authToken: string | null,
    retryCount = 0
): Promise<Response> => {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${authToken}`,
            accept: 'application/ld+json', // includes hydra info. Use 'application/json' for response data only
        },
    });

    if (response.status === 401) {
        // Something is wrong with the auth token, clear it
        // and try again
        _authTokenPromise = null;
        await getJwtToken();

        if (retryCount < MAX_RETRY_COUNT) {
            retryCount++;
            console.log(`retrying call ${retryCount}`);
            return _fetch(url, authToken, 1);
        } else {
            throw new Error(`Auth token error, max retry`);
        }
    } else {
        // Reset the retry since it's not an auth issue
        retryCount = 0;

        switch (response.status) {
            case 400:
                throw new Error(`request not successful`);
            default:
                console.log('returning response');
                return response;
        }
    }
};

export const getAbTest = async (id: string): Promise<ABTestHydraMember> => {
    const response = await experimentsFetch(`/ab_tests/${id}`);

    return response?.json();
};

export const getABTests = async (
    page = 1,
    label?: string
): Promise<ABTestsResponse> => {
    const params = encode({ page, label });
    const response = await experimentsFetch('/ab_tests', params);

    return response?.json();
};

export const putAbTest = async (
    id: string,
    body: ExperimentsAbTestPutAndPostBody
): Promise<ABTestHydraMember | ABTestHydraError | null> => {
    const response = await experimentsPut(`/ab_tests/${id}`, body);
    return response?.json();
};

export const postAbTest = async (
    body: ExperimentsAbTestPutAndPostBody
): Promise<ABTestHydraMember | null> => {
    const response = await experimentsPost('/ab_tests', body);
    return response?.json();
};

export const getVariantsForAbTest = async (
    testId: number
): Promise<ABTestVariantResponse> => {
    const response = await experimentsFetch(`/ab_tests/${testId}/variants`);

    return response?.json();
};

export const putAbtestVariant = async (
    variantId: string,
    body: ExperimentsAbTestVariantPutAndPostBody
): Promise<ABTestVariantHydraMember> => {
    const response = await experimentsPut(
        `/ab_test_variants/${variantId}`,
        body
    );
    return response?.json();
};

export const postAbtestVariant = async (
    body: ExperimentsAbTestVariantPutAndPostBody
): Promise<ABTestVariantHydraMember> => {
    const response = await experimentsPost('/ab_test_variants', body);
    return response?.json();
};

export const getAbTestVariants = async () => {
    const response = await experimentsFetch(`/ab_test_variants`);

    return response?.json();
};

// Get Features with the given parameters
export const getFeatures = async (
    page = 1,
    label?: string
): Promise<FeaturesResponse> => {
    const params = encode({ page, label });
    const response = await experimentsFetch('/features', params);

    return response?.json();
};
