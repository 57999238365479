import { useState, useEffect } from 'react';
import {
    getAbTestsFilterKey,
    getAsyncExperimentsAbTests,
    getAsyncExperimentsAbTest,
    postAsyncExperimentsAbTest,
    putAsyncExperimentsAbTest,
    AbTestExperiments,
    getAsyncVariantsForAbTest,
    putAsyncExperimentsAbTestVariant,
    postAsyncExperimentsAbTestVariant,
} from 'slice/experiments.slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import AbTestForm, {
    AbTestFormValuesConvertedTrafficPools,
} from 'components/experiments/AbTestForm';
import AbTestList from 'components/experiments/AbTestList';

import { Link, useNavigate, useParams } from 'react-router-dom';
import SearchInput from 'components/SearchInput';
import PageScrollHandler from 'components/PageScrollHandler';

import './AbTests.scss';
import {
    ExperimentsAbTestPutAndPostBody,
    ExperimentsAbTestVariantPutAndPostBody,
} from 'api/types/experiments-abtests';
import { AbTestVariantFormValues } from 'components/experiments/AbTestVariantForm';
import Button from 'react-bootstrap/Button';

export default function AbTests() {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    const [closeToBottom, setCloseToBottom] = useState(false);
    const [pages, setPages] = useState<{ [key: string]: number }>({ '': 1 });

    const { abTests, abTestsCount, abTestsStatus } = useAppSelector(
        (state) => state.experiments
    );

    const [labelFilter, setLabelFilter] = useState('');
    const [labelQuery, setLabelQuery] = useState('');

    const filterKey = getAbTestsFilterKey({
        label: labelQuery,
    });

    const abTest = abTests.find((q) => id !== 'new' && q.id === Number(id));

    const queriedCount = abTestsCount[filterKey] || 0;

    const displayedAbTests = abTests.filter((q) => {
        return labelFilter === '' || q.label.indexOf(labelFilter) !== -1;
    });

    const labelFilterTriggerQuery = (label: string) => {
        setLabelQuery(label);
        setPages({
            ...pages,
            [getAbTestsFilterKey({
                label: label,
            })]: 1,
        });
    };

    const scrollEvent = (isCloseToBottom: boolean) => {
        setCloseToBottom(isCloseToBottom);
    };

    if (
        closeToBottom &&
        displayedAbTests.length < queriedCount &&
        abTestsStatus !== 'loading'
    ) {
        let page = (pages[filterKey] || 1) + 1;
        setPages({ ...pages, [filterKey]: page });
        setCloseToBottom(false);
    }

    const handleModalClose = () => {
        navigate('/experiments/abTests');
    };

    const cleanFormDataForSend = (
        formData: AbTestFormValuesConvertedTrafficPools
    ): ExperimentsAbTestPutAndPostBody => {
        let returnVal = {} as ExperimentsAbTestPutAndPostBody;
        returnVal.label = formData.label;
        returnVal.name = formData.name;
        returnVal.status = Number(formData.status);
        returnVal.ios = formData.ios;
        returnVal.android = formData.android;
        returnVal.kindle = formData.kindle;
        returnVal.web = formData.web;
        returnVal.bucketAutomatically = formData.bucketAutomatically;
        returnVal.bucketByIdentityType = formData.bucketByIdentityType;
        returnVal.trafficPools = formData.trafficPools;
        if (formData.finalVariant) {
            returnVal.finalVariant = formData.finalVariant;
        }
        if (formData.forcedVariant) {
            returnVal.forcedVariant = formData.forcedVariant;
        }
        returnVal.technicalOwner = formData.technicalOwner;
        returnVal.productOwner = formData.productOwner;
        returnVal.category = formData.category;
        returnVal.subcategory = formData.subcategory;
        returnVal.statusLabel = formData.statusLabel;

        return returnVal;
    };

    const handleFormSaveClick = (
        formData: AbTestFormValuesConvertedTrafficPools
    ) => {
        let cleanedFormData = cleanFormDataForSend(formData);
        if (!abTest) {
            dispatch(postAsyncExperimentsAbTest(cleanedFormData));
            return;
        }
        dispatch(
            putAsyncExperimentsAbTest({
                id: abTest.id.toString(),
                body: cleanedFormData,
            })
        );
    };

    const cleanVariantFormDataForSend = (
        formData: AbTestVariantFormValues
    ): ExperimentsAbTestVariantPutAndPostBody => {
        let returnVal = {} as ExperimentsAbTestVariantPutAndPostBody;
        returnVal.label = formData.label;
        returnVal.description = formData.description;
        returnVal.weight = Number(formData.weight);
        // ORDER MATTERS in this object
        // very hard to trace bug where the BE fails on this call if the
        // "test" value is after "status" value in the object.
        // reason on BE: BE takes in props in order and
        // needs to know which test to set status for
        if (abTest) {
            returnVal.test = `/ab_tests/${abTest.id}`;
        }
        returnVal.status = Number(formData.status);
        return returnVal;
    };

    const handleSaveVariantClick = (
        formData: AbTestVariantFormValues,
        id?: number
    ) => {
        let cleanedFormData = cleanVariantFormDataForSend(formData);
        if (!id) {
            dispatch(postAsyncExperimentsAbTestVariant(cleanedFormData));
            return;
        }
        dispatch(
            putAsyncExperimentsAbTestVariant({
                variantId: id.toString(),
                body: cleanedFormData,
            })
        );
    };

    useEffect(() => {
        const page = pages[filterKey] || 1;
        dispatch(
            getAsyncExperimentsAbTests({
                page,
                label: labelQuery,
            })
        );
    }, [dispatch, pages, filterKey, labelQuery]);

    useEffect(() => {
        if (abTest) {
            dispatch(
                getAsyncVariantsForAbTest((abTest as AbTestExperiments)?.id)
            );
        }
    }, [dispatch, abTest]);

    useEffect(() => {
        if (id && id !== 'new') {
            dispatch(getAsyncExperimentsAbTest({ id }));
        }
    }, [dispatch, id]);

    return (
        <div className="experiments-abTests">
            <PageScrollHandler scrollEvent={scrollEvent} pixelsToBottom={300} />
            <div className="abTests-header">
                <Link to={`/experiments/abTests/new`}>
                    <Button variant="outline-primary" size="sm">
                        New AB Test
                    </Button>
                </Link>
            </div>
            {!!id && (
                <AbTestForm
                    onClose={handleModalClose}
                    onSave={handleFormSaveClick}
                    onSaveVariant={handleSaveVariantClick}
                />
            )}
            <form>
                <div className="row mb-3">
                    <div className="col-auto">
                        <label htmlFor="emailInput" className="col-form-label">
                            Label
                        </label>
                    </div>
                    <div className="col-auto">
                        <SearchInput
                            className="form-control"
                            id="labelInput"
                            onChange={(e) => setLabelFilter(e.target.value)}
                            value={labelFilter}
                            onTriggerQuery={labelFilterTriggerQuery}
                        />
                    </div>
                </div>
            </form>
            <AbTestList displayedAbTests={displayedAbTests} />
            {displayedAbTests.length >= queriedCount && (
                <div className="text-center my-2 fw-bold">
                    - no more AB Tests to fetch ({queriedCount}/{queriedCount})
                    -
                </div>
            )}
        </div>
    );
}
