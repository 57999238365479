import React from 'react';
import { PaymentsAccount } from 'slice/payments.slice';
import { trimId } from 'lib/util';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface PaymentAccountsProp {
    accounts: PaymentsAccount[];
}

export default function PaymentAccounts({ accounts }: PaymentAccountsProp) {
    return (
        <div>
            <table className="table">
                <thead>
                    <tr className="text-uppercase">
                        <th>id</th>
                        <th>email</th>
                        <th>transactions</th>
                        <th>created</th>
                        <th>updated</th>
                    </tr>
                </thead>
                <tbody>
                    {accounts.map(
                        ({ id, email, transactions, created, updated }) => (
                            <tr key={id}>
                                <td title={id}>
                                    <Link to={`/payments/accounts/${id}`}>
                                        {trimId(id)}
                                    </Link>
                                </td>
                                <td>
                                    <Link to={`/payments/accounts/${id}`}>
                                        {email}
                                    </Link>
                                </td>
                                <td>
                                    {transactions
                                        .slice(0, 5)
                                        .map((transaction) => (
                                            <div
                                                key={transaction}
                                                title={transaction}
                                            >
                                                <Link
                                                    to={`/payments${transaction}`}
                                                >
                                                    {trimId(transaction)}
                                                </Link>
                                            </div>
                                        ))}
                                    {transactions.length > 5 && (
                                        <div>
                                            + {transactions.length - 5} more ...
                                        </div>
                                    )}
                                </td>
                                <td>{moment(created).fromNow()}</td>
                                <td>{moment(updated).fromNow()}</td>
                            </tr>
                        )
                    )}
                </tbody>
            </table>
        </div>
    );
}
