export const WEB_HOSTS: EnvironmentMetaData[] = [
    {
        host: 'https://webstaging3.storymagic.co',
        build: {
            urls: [
                'https://jenkins.storymagic.co:8082/view/k8s/job/webstaging3-deploy-and-build-web-k8s/',
            ],
            type: 'jenkins',
            description: 'Used for pre production testing.',
        },
    },
    { host: 'https://webstaging4.storymagic.co' },
    { host: 'https://webreleasecandidate.storymagic.co' },
    { host: 'https://epic-staging-master.storymagic.co' },
    { host: 'https://webfreemiumstaging.storymagic.co' },
    { host: 'https://webfdpod.storymagic.co' },
    {
        host: 'https://www.getepic.com',
        build: {
            urls: [
                'https://jenkins.storymagic.co:8082/view/Web-Prod/job/Deploy%20webnode%20production%20k8s/',
            ],
            type: 'jenkins',
            description: 'Production build.',
        },
    },
    { host: 'https://webcepod.storymagic.co' },
    { host: 'https://webcgpod.storymagic.co' },
    { host: 'https://webkepod.storymagic.co' },
    { host: 'https://webqa.storymagic.co' },
    { host: 'https://webtgpod.storymagic.co' },
];
