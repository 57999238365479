import { Alert } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { FeaturesExperiments } from 'slice/experiments.slice';
import { useAppSelector } from 'app/hooks';

export default function FeaturesList({
    displayedFeatures,
}: {
    displayedFeatures: FeaturesExperiments[];
}) {
    const { featuresStatus } = useAppSelector((state) => state.experiments);
    return (
        <div>
            <div className="experiments-featuresList">
                <Table striped bordered hover size="sm">
                    <thead>
                        <tr>
                            <th>Label</th>
                            <th>Enabled</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedFeatures.map(
                            ({ id, label, enabled, status }, index) => (
                                <tr key={id}>
                                    <td>{label}</td>
                                    <td>{enabled.toString()}</td>
                                    <td>{status ? 'Inactive' : 'Active'}</td>
                                    <td>Edit</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </Table>
            </div>
            {!Object.keys(displayedFeatures).length &&
                featuresStatus !== 'loading' && (
                    <Alert variant="danger">No AB test data found</Alert>
                )}
        </div>
    );
}
