import React from 'react';
import PaymentsTransactionErrors from 'components/payments/PaymentsTransactionErrors';
import PaymentsHeader from 'components/payments/PaymentsHeader';
import PageHeader from 'components/PageHeader';

export default function PaymentsTransactionsErrorsPage() {
    return (
        <div className="p-2">
            <PaymentsHeader currentPage="transactionErrors" />
            <PageHeader>Payments Transaction Errors</PageHeader>
            <PaymentsTransactionErrors />
        </div>
    );
}
