export interface ServiceDetail {
    type: 'dev' | 'staging' | 'production';
    contents: string | JSX.Element;
    name: string;
}

type ServiceDetailsProp = {
    servicesDetails: ServiceDetail[];
};

export default function ServiceDetails({
    servicesDetails,
}: ServiceDetailsProp) {
    return (
        <div className="service-details row mx-0">
            {servicesDetails.map(({ type, contents, name }) => (
                <div
                    key={name}
                    className={`p-2 ${type} col-${
                        12 / Math.min(servicesDetails.length, 3)
                    }`}
                >
                    <span className="fw-bold">
                        {name} <span className="text-uppercase">"{type}"</span>
                    </span>{' '}
                    {contents}
                </div>
            ))}
        </div>
    );
}
