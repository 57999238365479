import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useState, useEffect } from 'react';
import {
    getFeaturesFilterKey,
    getAsyncExperimentsFeatures,
} from 'slice/experiments.slice';

import FeaturesList from 'components/experiments/FeaturesList';
import SearchInput from 'components/SearchInput';
import PageScrollHandler from 'components/PageScrollHandler';

export default function Features() {
    const dispatch = useAppDispatch();
    const [closeToBottom, setCloseToBottom] = useState(false);
    const [pages, setPages] = useState<{ [key: string]: number }>({ '': 1 });

    const { features, featuresCount, featuresStatus } = useAppSelector(
        (state) => state.experiments
    );

    const [labelFilter, setLabelFilter] = useState('');
    const [labelQuery, setLabelQuery] = useState('');

    const filterKey = getFeaturesFilterKey({
        label: labelQuery,
    });

    const queriedCount = featuresCount[filterKey] || 0;

    const displayedFeatures = features.filter((q) => {
        return labelFilter === '' || q.label.indexOf(labelFilter) !== -1;
    });

    const labelFilterTriggerQuery = (label: string) => {
        setLabelQuery(label);
        setPages({
            ...pages,
            [getFeaturesFilterKey({
                label: label,
            })]: 1,
        });
    };

    const scrollEvent = (isCloseToBottom: boolean) => {
        setCloseToBottom(isCloseToBottom);
    };

    if (
        closeToBottom &&
        displayedFeatures.length < queriedCount &&
        featuresStatus !== 'loading'
    ) {
        let page = (pages[filterKey] || 1) + 1;
        setPages({ ...pages, [filterKey]: page });
        setCloseToBottom(false);
    }

    useEffect(() => {
        const page = pages[filterKey] || 1;
        dispatch(
            getAsyncExperimentsFeatures({
                page,
            })
        );
    }, [dispatch, pages, filterKey]);

    return (
        <div>
            <PageScrollHandler scrollEvent={scrollEvent} pixelsToBottom={300} />
            <form>
                <div className="row mb-3">
                    <div className="col-auto">
                        <label htmlFor="emailInput" className="col-form-label">
                            Label
                        </label>
                    </div>
                    <div className="col-auto">
                        <SearchInput
                            className="form-control"
                            id="labelInput"
                            onChange={(e) => setLabelFilter(e.target.value)}
                            value={labelFilter}
                            onTriggerQuery={labelFilterTriggerQuery}
                        />
                    </div>
                </div>
            </form>
            <FeaturesList displayedFeatures={displayedFeatures} />
            {displayedFeatures.length >= queriedCount && (
                <div className="text-center my-2 fw-bold">
                    - no more Features to fetch ({queriedCount}/{queriedCount})
                    -
                </div>
            )}
        </div>
    );
}
