import React, { useEffect } from 'react';
import PaymentsAccount from 'components/payments/PaymentsAccount';
import { useParams } from 'react-router-dom';
import PaymentsHeader from 'components/payments/PaymentsHeader';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    getAsyncPaymentsAccount,
    getAsyncPaymentsTransactions,
    putAsyncPaymentsAccounts,
} from 'slice/payments.slice';

export default function PaymentsAccountPage() {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { accounts, transactions, transactionsStatus } = useAppSelector(
        (state) => state.payments
    );
    const account = accounts.find((q) => q.id === id);
    const accountTransactions = transactions.filter((q) => q.account.id === id);

    useEffect(() => {
        if (id) {
            dispatch(getAsyncPaymentsAccount({ id }));
            // still not working
            // dispatch(getAsyncPaymentsTransactions({ 'account.id': id }));
            dispatch(getAsyncPaymentsTransactions());
        }
    }, [dispatch, id]);

    return (
        <div className="p-2">
            <PaymentsHeader />
            <PageHeader>Payments Account</PageHeader>
            {!!account && (
                <PaymentsAccount
                    account={account}
                    transactions={accountTransactions
                        .slice()
                        .sort((a, b) =>
                            new Date(a.updated).getTime() >
                            new Date(b.updated).getTime()
                                ? -1
                                : 1
                        )}
                    transactionsStatus={transactionsStatus}
                    onSave={({ id, email }) =>
                        dispatch(
                            putAsyncPaymentsAccounts({ id, body: { email } })
                        )
                    }
                />
            )}
        </div>
    );
}
