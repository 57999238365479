import { useAppSelector } from 'app/hooks';
import React, { useState } from 'react';
import { ErrorState } from 'slice/types/error';

export default function Errors() {
    const [openErrors, setOpenErrors] = useState<ErrorState[]>([]);
    const { errors } = useAppSelector((state) => state.errors);
    const { errors: paymentErrors } = useAppSelector((state) => state.payments);
    const { errors: experimentsErrors } = useAppSelector(
        (state) => state.experiments
    );

    const combinedErrors = [errors, paymentErrors, experimentsErrors].flat();

    const openError = (error: ErrorState) => {
        setOpenErrors([...openErrors, error]);
    };

    const closeError = (error: ErrorState) => {
        setOpenErrors(openErrors.filter((q) => q !== error));
    };

    return (
        <>
            {combinedErrors.map((error, index) => (
                <div
                    className="alert alert-danger position-relative"
                    role="alert"
                    key={`${error.key}${index}`}
                >
                    <span>{error.message}</span>
                    {!!error.html && openErrors.includes(error) && (
                        <>
                            <div
                                className="html-error html-close-error"
                                onClick={() => closeError(error)}
                            ></div>
                            <div
                                dangerouslySetInnerHTML={{ __html: error.html }}
                            ></div>
                        </>
                    )}
                    {!!error.html && !openErrors.includes(error) && (
                        <>
                            <div
                                className="html-error html-open-error"
                                onClick={() => openError(error)}
                            ></div>
                        </>
                    )}
                </div>
            ))}
        </>
    );
}
