import React from 'react';

interface GenericObjectProps {
    object: object;
    omit?: string[];
}

export default function GenericObject({ object, omit }: GenericObjectProps) {
    const flattenObject = (
        object: object,
        flattenedObject: { [key: string]: any } = {},
        keyPrefix = ''
    ) => {
        Object.entries(object).forEach(([key, value]) => {
            const combinedKey = `${keyPrefix}${key}`;
            if (omit && omit.includes(combinedKey)) {
                return;
            }
            if (value instanceof Array) {
                flattenedObject[combinedKey] = value.join(', ');
                return;
            }

            if (value && typeof value === 'object') {
                flattenObject(value, flattenObject, `${combinedKey}.`);
                return;
            }

            flattenedObject[combinedKey] = value;
        });

        return flattenedObject;
    };

    const flattenedTransaction = flattenObject(object);
    return (
        <div>
            {Object.entries(flattenedTransaction).map(([key, value]) => (
                <div className="row" key={key}>
                    <div className="col-3">{key}:</div>
                    <div className="col-9">{value}</div>
                </div>
            ))}
        </div>
    );
}
