import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AbTests from 'pages/AbTests';
import Features from 'pages/Features';
import { useParams, useNavigate } from 'react-router-dom';

export default function ExperimentsPage() {
    const DEFAULT_ACTIVE_TAB = 'abTests';

    const { tab } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!tab) {
            navigate(`/experiments/${DEFAULT_ACTIVE_TAB}`);
        }
    }, [navigate, tab]);

    return (
        <div className="experiments-abTestsContainer p-2">
            <h2>Experiments</h2>
            <Tabs
                activeKey={tab}
                onSelect={(eventKey) => {
                    if (!eventKey) {
                        return;
                    }
                    if (tab !== eventKey) {
                        navigate(`/experiments/${eventKey}`);
                    }
                }}
                className="mb-3"
            >
                <Tab eventKey="abTests" title="AB Tests">
                    <AbTests />
                </Tab>
                <Tab eventKey="abTestUsers" title="AB Test Users">
                    TBD
                </Tab>
                <Tab eventKey="featureList" title="Features">
                    <Features />
                </Tab>
            </Tabs>
        </div>
    );
}
