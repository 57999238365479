type EXPERIMENTS_API_HOST_DEFINITION =
    | ''
    | 'https://experiment-dev.getepic.com'
    | 'https://experiment-staging.getepic.com'
    | 'https://experiment.getepic.com';

export const API_HOST = process.env.REACT_APP_API_HOST || '';

export const PAYMENTS_API_HOST =
    process.env.REACT_APP_PAYMENTS_API_HOST ||
    'https://payments-dev.storymagic.co/';

export const EXPERIMENTS_API_HOST: EXPERIMENTS_API_HOST_DEFINITION =
    (process.env
        .REACT_APP_EXPERIMENTS_API_HOST as EXPERIMENTS_API_HOST_DEFINITION) ||
    '';
export const EXPERIMENTS_TOKEN: string =
    process.env.REACT_APP_EXPERIMENTS_TOKEN || '';
