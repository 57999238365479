import { PAYMENTS_API_HOST } from 'config';
import { encode } from 'querystring';
import { get, put } from 'api/api.service';
import {
    PaymentsAccountsResponse,
    PaymentsAccountsHydraMember as PaymentsAccountResponse,
} from 'api/types/payments-accounts-get';
import {
    PaymentsTransactionErrorsQuery,
    PaymentsTransactionErrorsResponse,
} from 'api/types/payments-transaction-errors-get';
import {
    PaymentTransactionsQuery,
    PaymentTransactionsResponse,
    PaymentTransactionsHydraMember as PaymentTransactionResponse,
} from './types/payments-transactions-get';
import { ApiError } from 'classes/ApiError';

const _get = async (url: string): Promise<Response | ApiError> => {
    const response = await get(url).catch((e: Error) => e);
    if (response instanceof Error) {
        return new ApiError(response.message);
    }

    if (!response.ok) {
        const html = await response.text();

        return new ApiError(
            `An ${response.status} error occurred for "${url}"`,
            response.status,
            html
        );
    }

    return response;
};

export const getAccounts = async (
    page = 1,
    email = ''
): Promise<PaymentsAccountsResponse | null> => {
    const query = encode({ page, email });
    const url = new URL('/accounts', PAYMENTS_API_HOST).href;
    const response = await get(`${url}?${query}`).catch((e) => {});
    if (!response) {
        return null;
    }

    return response.json();
};

export const getAccount = async (
    id: string
): Promise<PaymentsAccountResponse | null> => {
    const url = new URL(`/accounts/${id}`, PAYMENTS_API_HOST).href;
    const response = await get(url).catch((e) => {});
    if (!response) {
        return null;
    }

    return response.json();
};

export const putAccount = async (
    id: string,
    body: { email: string }
): Promise<PaymentsAccountResponse | null> => {
    const url = new URL(`/accounts/${id}`, PAYMENTS_API_HOST).href;
    const response = await put(url, body, {
        'Content-Type': 'application/json',
    }).catch((e) => {});
    if (!response) {
        return null;
    }

    return response.json();
};

export const getTransactionErrors = async (
    queryParams: PaymentsTransactionErrorsQuery = {}
): Promise<PaymentsTransactionErrorsResponse | ApiError> => {
    const query = encode({ ...queryParams });
    const url = new URL('/transaction_errors', PAYMENTS_API_HOST).href;
    const response = await _get(`${url}?${query}`);
    if (response instanceof ApiError) {
        return response;
    }

    return response.json();
};

export const getTransactions = async (
    queryParams: PaymentTransactionsQuery = {}
): Promise<PaymentTransactionsResponse | ApiError> => {
    const query = encode({ ...queryParams });
    const url = new URL('transactions', PAYMENTS_API_HOST).href;
    const response = await _get(`${url}?${query}`);
    if (response instanceof ApiError) {
        return response;
    }

    return response.json();
};

export const getTransaction = async (
    id: string
): Promise<PaymentTransactionResponse | null> => {
    const url = new URL(`/transactions/${id}`, PAYMENTS_API_HOST).href;
    const response = await get(url).catch((e) => {});
    if (!response) {
        return null;
    }

    return response.json();
};
