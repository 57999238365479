import Button from 'react-bootstrap/Button';
import { ABTEST_STATUS, ABTEST_VARIANT_STATUS } from 'api/experiments.service';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { getAsyncExperimentsAbTest } from 'slice/experiments.slice';
import { useForm, SubmitHandler } from 'react-hook-form';

export type AbTestVariantFormValues = {
    id?: number | string;
    label: string;
    description: string;
    weight: string;
    status: string;
};

interface AbTestVariantProps {
    id?: number;
    onSave: (props: AbTestVariantFormValues, id?: number) => void;
    experimentStatus?: number;
}

export default function AbTestVariantForm({ id, onSave }: AbTestVariantProps) {
    const dispatch = useAppDispatch();
    const { abTestVariants, abTests } = useAppSelector(
        (state) => state.experiments
    );
    const abTestVariant = abTestVariants.find((q) => q.id === id);
    const abTest = abTests.find(
        (q) =>
            q.id.toString() ===
            abTestVariant?.test.substring(
                abTestVariant?.test.lastIndexOf('/') + 1
            )
    );

    const { register, handleSubmit, reset } =
        useForm<AbTestVariantFormValues>();

    const onSubmit: SubmitHandler<AbTestVariantFormValues> = (
        data: AbTestVariantFormValues
    ) => {
        onSave(data, abTestVariant?.id);
    };

    useEffect(() => {
        if (id && !abTest) {
            let testId = abTestVariant?.test.substring(
                abTestVariant?.test.lastIndexOf('/') + 1
            );
            testId && dispatch(getAsyncExperimentsAbTest({ id: testId }));
        }
        reset();
    }, [dispatch, id, abTestVariant, abTest, reset]);

    useEffect(() => {
        if (abTestVariant) {
            reset();
        }
    }, [abTestVariant, reset]);

    const styles = {
        datesSpacer: {
            marginBottom: '1rem',
            fontSize: '0.7rem',
            fontWeight: '700',
        },
    };
    const onError = (errors: any, e: any) => console.log(errors, e);

    return (
        <div>
            <div style={styles.datesSpacer}>
                <p>
                    Date Created:{' '}
                    {abTestVariant &&
                        new Date(abTestVariant?.dateCreated).toString()}
                </p>
                <p>
                    Date Modified:{' '}
                    {abTestVariant &&
                        new Date(abTestVariant?.dateModified).toString()}
                </p>
            </div>
            <form
                noValidate
                onSubmit={handleSubmit(onSubmit, onError)}
                className="ab-test-form"
            >
                {/* label */}
                <div className="mb-3">
                    <label htmlFor="label">
                        Label (Unique, locked while Test Status is Active)
                    </label>
                    <input
                        type="text"
                        defaultValue={abTestVariant?.label || ''}
                        {...register('label', {
                            required: true,
                        })}
                        readOnly={abTest?.status === ABTEST_STATUS.ACTIVE}
                    />
                </div>

                {/* description */}
                <div className="mb-3">
                    <label htmlFor="description">Description</label>
                    <input
                        type="text"
                        defaultValue={abTestVariant?.description || ''}
                        {...register('description', {
                            required: true,
                        })}
                    />
                </div>

                {/* weight */}
                <div className="mb-3">
                    <label htmlFor="weight">
                        Weight (Unique, locked while Test Status is Active)
                    </label>
                    <input
                        type="number"
                        defaultValue={abTestVariant?.weight.toString() || '0'}
                        {...register('weight', {
                            required: true,
                        })}
                        readOnly={abTest?.status === ABTEST_STATUS.ACTIVE}
                    />
                </div>

                {/* status */}
                <div className="mb-3">
                    <label htmlFor="status">Status</label>
                    <select
                        defaultValue={
                            (abTestVariant &&
                                abTestVariant.status.toString()) ||
                            '1'
                        }
                        {...register('status')}
                    >
                        {Object.entries(ABTEST_VARIANT_STATUS).map(
                            ([name, value], index) => (
                                <option key={index} value={value}>
                                    {name.toLowerCase()}
                                </option>
                            )
                        )}
                    </select>
                </div>

                <Button
                    type="submit"
                    // disabled={!formState.isValid}
                    variant="primary"
                >
                    Save
                </Button>
            </form>
        </div>
    );
}
