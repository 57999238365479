export const ADMIN_HOSTS: EnvironmentMetaData[] = [
    {
        host: 'https://web-admin-staging.storymagic.co',
        build: {
            urls: [
                'https://github.com/Epic/web-admin/blob/master/.github/workflows/argoCD.yml',
                'https://argocd.getepic.com/applications/webadmin-staging?orphaned=false&resource=',
                'https://github.com/Epic/web-admin/blob/master/.github/workflows/build.yml',
            ],
            type: 'Github Actions/ArgoCD',
            description: 'Production build.',
        },
    },
    { host: 'https://web-admin-dev.storymagic.co' },
];
