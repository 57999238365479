import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { logout } from 'slice/user.slice';

import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import Navigation from './Navigation';
import { getAsyncEnvironment } from 'slice/environments.slice';

export default function Header() {
    const dispatch = useAppDispatch();
    const { environments } = useAppSelector((state) => state.environments);

    const getCurrentAdminEnvironment = () => {
        const curHost = window.location.host;
        return getAsyncEnvironment(`https://${curHost}`);
    };

    useEffect(() => {
        dispatch(getCurrentAdminEnvironment());
    }, [dispatch]);

    const onLogout = () => {
        dispatch(logout());
    };

    return (
        // Set expand=false when we add too many items to the nav bar. That will move everything to the
        // side tray and force it to always render.
        <Navbar
            bg="light"
            expand="md"
            sticky="top"
            className="p-3"
            collapseOnSelect
        >
            <Navbar.Brand href="#"></Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Offcanvas placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Navigate</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="align-self-center">
                        Git Hash:{' '}
                        {environments.length &&
                            environments
                                .find(
                                    ({ host }) =>
                                        host ===
                                        `https://${window.location.host}`
                                )
                                ?.environment?.git.commit.substring(0, 7)}
                    </div>
                    <Navigation />
                    <Button onClick={onLogout}>Logout</Button>
                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </Navbar>
    );
}
