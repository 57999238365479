import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';

export default function Navigation() {
    return (
        <Nav className="justify-content-end flex-grow-1 pe-3">
            {/* eventKey is needed for collapseOnSelect (in Header.tsx) to behave correctly */}
            <Nav.Link as={NavLink} eventKey='home' to="/">Home</Nav.Link>
            <Nav.Link as={NavLink} eventKey='environments' to="/environments">Environments</Nav.Link>
            <Nav.Link as={NavLink} eventKey='payments' to="/payments">Payments</Nav.Link>
            <Nav.Link as={NavLink} eventKey='experiments' to="/experiments">Experiments</Nav.Link>
        </Nav>
    );
}