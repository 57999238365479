import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from 'slice/user.slice';
import environmentReducer from 'slice/environments.slice';
import paymentsReducer from 'slice/payments.slice';
import experimentsReducer from 'slice/experiments.slice';
import errorsReducer from 'slice/errors.slice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        environments: environmentReducer,
        payments: paymentsReducer,
        experiments: experimentsReducer,
        errors: errorsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
