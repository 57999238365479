import React, { useEffect } from 'react';
import { Route, Navigate, useLocation, Outlet, Routes } from 'react-router-dom';
import LoginPage from 'pages/LoginPage';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import HomePage from 'pages/HomePage';
import Header from 'components/Header';
import { getUserAsync } from 'slice/user.slice';
import EnvironmentPage from 'pages/Environments';
import PaymentsAccountPage from 'pages/PaymentsAccount';
import PaymentsAccountsPage from 'pages/PaymentsAccounts';
import PaymentsTransactionErrors from 'pages/PaymentsTransactionErrors';
import ExperimentsPage from 'pages/Experiments';
import PaymentsTransactionsPage from 'pages/PaymentsTransactions';
import PaymentsTransactionPage from 'pages/PaymentTransaction';
import ServiceDetails, { ServiceDetail } from 'components/ServiceDetails';
import { PAYMENTS_API_HOST, API_HOST } from 'config';

import Container from 'react-bootstrap/Container';
import Errors from 'components/Errors';
import { getAsyncHost } from 'slice/experiments.slice';

function App() {
    const dispatch = useAppDispatch();
    const { user, initialSet } = useAppSelector((state) => state.user);
    const { host } = useAppSelector((state) => state.experiments);
    useEffect(() => {
        if (!initialSet) {
            dispatch(getUserAsync());
        }
        if (user && !host) {
            dispatch(getAsyncHost());
        }
    }, [dispatch, user, initialSet, host]);

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <HomePage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/environments"
                    element={
                        <RequireAuth>
                            <EnvironmentPage />
                        </RequireAuth>
                    }
                />
                <Route path="payments">
                    <Route
                        path=""
                        element={<Navigate to="/payments/accounts" replace />}
                    />
                    <Route
                        path="accounts"
                        element={
                            <RequireAuth>
                                <PaymentsAccountsPage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="accounts/:id"
                        element={
                            <RequireAuth>
                                <PaymentsAccountPage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="transaction-errors"
                        element={
                            <RequireAuth>
                                <PaymentsTransactionErrors />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="transactions"
                        element={
                            <RequireAuth>
                                <PaymentsTransactionsPage />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="transactions/:id"
                        element={
                            <RequireAuth>
                                <PaymentsTransactionPage />
                            </RequireAuth>
                        }
                    />
                </Route>
                <Route
                    path="/experiments"
                    element={
                        <RequireAuth>
                            <ExperimentsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/experiments/:tab"
                    element={
                        <RequireAuth>
                            <ExperimentsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/experiments/:tab/:id"
                    element={
                        <RequireAuth>
                            <ExperimentsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/login"
                    element={
                        <RequireAnonymous>
                            <LoginPage />
                        </RequireAnonymous>
                    }
                />
            </Route>
        </Routes>
    );
}

function RequireAuth({ children }: { children: JSX.Element }) {
    const { user, initialSet } = useAppSelector((state) => state.user);
    let location = useLocation();

    if (!initialSet) {
        return <div></div>;
    }

    if (!user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

function RequireAnonymous({ children }: { children: JSX.Element }) {
    const { user, initialSet } = useAppSelector((state) => state.user);

    if (!initialSet) {
        return <div></div>;
    }

    if (user) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/" replace />;
    }

    return children;
}

function getServiceType(url: string) {
    if (url.match(/dev/)) {
        return 'dev';
    }

    if (url.match(/staging|release/)) {
        return 'staging';
    }

    return 'production';
}

function Layout() {
    const { user } = useAppSelector((state) => state.user);
    const { host } = useAppSelector((state) => state.experiments);
    const location = useLocation();

    const serviceDetails: ServiceDetail[] = [
        {
            name: 'EpicBackend',
            type: getServiceType(API_HOST),
            contents: API_HOST,
        },
    ];

    if (location.pathname.match(/experiment/)) {
        serviceDetails.push({
            name: 'experiments service',
            type: getServiceType(host),
            contents: host,
        });
    }

    if (location.pathname.match(/payment/)) {
        serviceDetails.push({
            name: 'payments service',
            type: getServiceType(PAYMENTS_API_HOST),
            contents: PAYMENTS_API_HOST,
        });
    }
    return (
        <>
            {!!user && <Header />}
            <Errors />
            <Container fluid>
                <div className="d-flex">
                    <div
                        className="flex-grow-1"
                        style={{ paddingBottom: 33 * serviceDetails.length }}
                    >
                        <Outlet />
                    </div>
                </div>
            </Container>
            <ServiceDetails servicesDetails={serviceDetails} />
        </>
    );
}

export default App;
