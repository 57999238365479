import { legacyGet } from './api.service';

export interface LoginResponse {
    result: {
        isAuthed?: boolean;
        username?: string;
        error?: string;
    };
}

export interface User {
    username: string;
}

export const login = async (
    username: string,
    password: string
): Promise<{ user?: User; error?: string }> => {
    const response = await legacyGet('WebAdminNew', 'noAuthAdminLogin', {
        username,
        password,
    });

    const data: LoginResponse = await response?.json();

    if (data.result.isAuthed && data.result.username) {
        return { user: { username: data.result.username } };
    }

    const error = data.result?.error || 'unknown error';

    return { error };
};

export const getUser = async (): Promise<{ user: User } | null> => {
    const response = await legacyGet('WebAdminNew', 'getLoggedUser');

    const data: LoginResponse = await response?.json();

    if (data.result.isAuthed && data.result.username) {
        return { user: { username: data.result.username } };
    }

    return null;
};

export const logout = async (): Promise<void> => {
    const response = await legacyGet('WebAdminNew', 'noAuthAdminLogout');

    const data: LoginResponse = await response?.json();

    if (data.result.error) {
        console.error(`unable to log out ${data.result.error}`);
    }
};
